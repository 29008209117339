export const FieldTypes = {
  text: 1,
  password: 2,
  checkbox: 3,
  submit: 4,
  dropdown: 5,
  select: 5,
  rating: 6,
  wysiwyg: 7,
  editor: 7,
  textarea: 8,
  number: 9,
  imageCrop: 10,
  money: 11,
  date: 12,
  dateTime: 13,
  time: 14,
  timeInterval: 15,
  color: 20,
  submitAndRedirect: 21,
};
