<template>
  <Loading v-if="loading" text="Načítám kartu..." />
  <template v-else>
    <div class="page-head">
      <h1>
        Karta:
        <span class="ft">
          {{ card.name }}
        </span>
      </h1>

      <div class="page-head__actions">
        <Button
          label="Zpět"
          @click="$router.push({ name: 'watch-cards-list' })"
          class="p-button-secondary"
          icon="pi pi-arrow-left"
        />
        <Button
          label="COMC"
          @click="visitCard"
          class="p-button-secondary"
          icon="pi pi-external-link"
          v-if="card"
        />
        <Button
          label="Smazat"
          @click="remove"
          class="p-button-secondary"
          icon="pi pi-trash"
          v-if="card"
        />
      </div>
    </div>

    <TabView class="p-tabview--no-bg" v-model:activeIndex="activeTab">
      <TabPanel header="Základní info">
        <div class="box">
          <prices-graph :card="card" />
        </div>
      </TabPanel>
      <TabPanel header="Historie cen" :disabled="card ? false : true">
        <div class="box">
          <prices-list :card="card" v-if="card" />
        </div>
      </TabPanel>
    </TabView>
  </template>
</template>

<script>
import { Cards } from '@/services/repositories/Watching';
import MainInfoForm from '../components/MainInfoForm';
import Button from 'primevue/button';
import Loading from '@/components/Loading.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import PricesGraph from '../components/card/PricesGraph.vue';
import PricesList from '../components/card/PricesList.vue';

export default {
  data() {
    return {
      card: null,
      loading: true,
      activeTab: 0,
      PRICES_TAB: 1,
    };
  },
  mounted() {
    this.initData();
    window.eventBus.on('prices-updated', () => {
      this.initData(this.card.id, this.PRICES_TAB);
    });
  },
  unmounted() {
    window.eventBus.off('prices-updated');
  },
  watch: {
    $route(to, from) {
      if (to.name == from.name) {
        this.initData();
      }
    },
  },
  methods: {
    initData(cardId, goToTab) {
      this.loading = true;
      cardId = cardId || this.$route.params.id;

      if (cardId) {
        Cards.getById(cardId).then((response) => {
          this.card = response;
          this.loading = false;
          document.title = this.card.name;

          if (goToTab) {
            this.activeTab = goToTab;
          }
        });
      } else {
        this.loading = false;
        this.card = null;
        document.title = 'Nová karta';
      }
    },
    visitCard() {
      window.open('https://www.comc.com/Cards/a/a/a/a/a/' + this.card.comcId, '_blank').focus();
    },
    remove() {
      this.$confirm.require({
        message: 'Opravdu chcete smazat kartu ' + this.card.name + '?',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-secondary',
        acceptClass: 'p-button-primary',
        accept: () => {
         Cards.remove(this.card).then(() => {
            this.$router.push({ name: 'watch-cards-list' });
            window.eventBus.emit(
              'success-toast',
              'Kaerta ' + this.card.name + ' byla odstraněna.'
            );
          });
        },
      });
    },
  },
  components: {
    MainInfoForm,
    Loading,
    Button,
    TabView,
    TabPanel,
    PricesGraph,
    PricesList,
  },
};
</script>

<style lang="scss" scoped></style>
