<template>
  <template v-if="!sent">
    <form @submit.prevent="handleSubmit" autocomplete="off">
      <div class="p-field p-grid required">
        <label class="p-col-fixed">Nové heslo</label>
        <div class="p-col">
          <Password v-model="password1" :feedback="false" />
        </div>
      </div>
      <div class="p-field p-grid required">
        <label class="p-col-fixed">Nové heslo znovu</label>
        <div class="p-col">
          <Password v-model="password2" :feedback="false" />
        </div>
      </div>
      <div class="p-field">
        <Button type="submit" label="Změnit heslo" />
      </div>
    </form>
  </template>

  <template v-if="sent">
    <p>Vaše heslo bylo úspěšně změněno.</p>
  </template>
</template>

<script>
import { User } from '@/services/User';
import Password from 'primevue/password';
import Button from 'primevue/button';

export default {
  name: 'ChangePassword',
  components: {
    Button,
    Password,
  },
  data() {
    return {
      password1: '',
      password2: '',
      sent: false,
    };
  },
  methods: {
    handleSubmit() {
      if (this.password1.length <= 0) {
        this.$toast.add({
          severity: 'error',
          summary: 'Vyplňte nové heslo.',
          life: 3000,
        });
        return;
      } else if (this.password2.length <= 0) {
        this.$toast.add({
          severity: 'error',
          summary: 'Zadejte nové heslo pro kontrolu i podruhé.',
          life: 3000,
        });
        return;
      } else if (this.password2 != this.password1) {
        this.$toast.add({
          severity: 'error',
          summary: 'Hesla se neshodují!',
          life: 3000,
        });
        return;
      }

      window.eventBus.emit('show-overlay-message', {
        text: 'Nastavuji nové heslo...',
      });

      User.changePassword(this.password1).then(() => {
        window.eventBus.emit('hide-overlay-message');
        this.sent = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.p-col-fixed {
  width: 220px;
}
</style>
