<template>
  <div :class="['menu', open ? 'menu--open' : null]">
    <div class="item-wrapper" v-for="item in items" :key="item.to">
      <router-link :to="{ name: item.to }" class="item">
        <span class="item__icon">
          <Icon :name="item.icon" />
        </span>
        <span class="item__text">
          {{ item.text }}
        </span>
      </router-link>

      <div class="submenu" v-if="item.subitems">
        <router-link
          :to="{ name: subitem.to }"
          class="subitem"
          v-for="subitem in item.subitems"
          :key="subitem.text"
        >
          <span class="subitem__text">
            {{ subitem.text }}
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icons/Icon';
import { mainMenuItemsService } from '@/services/menu/mainMenuItemsService';

export default {
  data() {
    return {
      open: true,
      items: mainMenuItemsService.get(),
      user: this.$store.getters.getUser,
    };
  },
  created() {
    window.eventBus.on('menu-opened', () => {
      this.open = true;
    });

    window.eventBus.on('menu-closed', () => {
      this.open = false;
    });

    window.eventBus.on('right-updated', (update) => {
      if (update.accountId == this.user.id) {
        this.$store.commit('revokeRight', update.right);
      }

      if (update.value == true) {
        this.$store.commit('grantRight', update.right);
      }

      this.menu = mainMenuItemsService.get();
    });
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.menu {
  width: 100%;
  --item-height: 45px;
}
.submenu {
  position: absolute;
  left: 100%;
  top: 0;
  transform: scaleX(0);
  transform-origin: center left;
  transition: transform 0.15s ease;
  width: var(--sidebar-open-width);
}
.item-wrapper {
  position: relative;
  &:hover {
    .submenu {
      transform: scaleX(100%);
    }
  }
}
.item {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 14px 0;
  border-right: 4px solid transparent;
  background: var(--primary-color);
  color: #fff;
  height: var(--item-height);
  &__icon {
    flex-shrink: 0;
    display: block;
    width: var(--sidebar-width);
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      --size: 17px;
      max-width: var(--size);
      max-height: var(--size);
    }
  }
  &__text {
    font-size: 14px;
    font-weight: 300;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
  }
  &:hover {
    background: #fff;
    color: var(--primary-color);
    .item__text {
      font-weight: 700;
    }
  }
  &.active {
    border-right-color: var(--secondary-color);
  }
}
.subitem {
  background: var(--primary-color);
  color: #fff;
  height: var(--item-height);
  display: flex;
  align-items: center;
  padding: 0 20px;
  &:hover {
    background: var(--secondary-color);
    color: var(--primary-color);
    text-decoration: none;
  }
}
</style>
