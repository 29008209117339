import { createRouter, createWebHistory } from 'vue-router';
import { User } from '@/services/User';
import dashboardRoutes from './modules/dashboard/routes/dashboardRoutes';
import loginRoutes from './modules/public/login/routes/loginRoutes';
import profileRoutes from './modules/profile/routes/profileRoutes';
import accountsRoutes from './modules/accounts/routes/accountsRoutes';
import registrationRoutes from './modules/public/registration/routes/registrationRoutes';
import watchRoutes from './modules/watch/routes/watchRoutes';
import collectionRoutes from './modules/collection/routes/collectionRoutes';

const routes = [
  ...loginRoutes,
  ...registrationRoutes,
  ...dashboardRoutes,
  ...profileRoutes,
  ...accountsRoutes,
  ...collectionRoutes,
  ...watchRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  const authRequired = !to.meta.public || !to.meta.public === true;
  const loggedIn = User.isLoggedIn();

  if (authRequired && !loggedIn) {
    return next({
      name: 'login',
      query: {
        redirect: to.fullPath,
      },
    });
  }

  next();
});

export default router;
