<template>
  <h1 class="heading heading--public tc cw">Obnova hesla</h1>

  <p class="message" v-if="!sent && !loading && !error">
    Zvolte si nové heslo.
  </p>

  <form
    v-on:submit.prevent="submit"
    class="form--public"
    v-if="!sent && !loading && !error"
  >
    <div class="public-field-wrapper">
      <Password
        v-model="password1"
        placeholder="Heslo"
        class="form__field"
        :feedback="false"
        toggleMask
      />
    </div>

    <div class="public-field-wrapper">
      <Password
        v-model="password2"
        placeholder="Heslo znovu"
        :feedback="false"
        class="form__field"
        toggleMask
      />
    </div>

    <Button class="p-button--xxl p-button--black form__submit" @click="submit">
      Nastavit nové heslo
    </Button>
  </form>

  <template v-if="sent && !loading && !error">
    <p class="message">Nové heslo bylo nastaveno.</p>
    <div class="bottom-links">
      <router-link :to="{ name: 'login' }" class="p-button">
        přihlásit
      </router-link>
    </div>
  </template>

  <p v-if="error" class="message">
    Bohužel tento odkaz již není platý. Vygenerujte si prosím odkaz
    <router-link :to="{ name: 'forgottenPassword' }" class="cw">
      znovu
    </router-link>
    .
  </p>

  <div class="bottom-links a a--fadeinbottom a--delay-900" v-if="!sent">
    <router-link :to="{ name: 'login' }" class="bottom-links__link">
      zpět
    </router-link>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Password from 'primevue/password';
import { User } from '@/services/User';

export default {
  data() {
    return {
      loading: true,
      password1: '',
      password2: '',
      sent: false,
      error: false,
    };
  },
  components: {
    Button,
    Password,
  },
  mounted() {
    window.eventBus.emit('show-overlay-message', {
      text: 'Kontroluji adresu...',
    });

    User.checkPasswordRestoreHash(this.$route.params.hash).then((response) => {
      window.eventBus.emit('hide-overlay-message');

      if (response.result != 'ok') {
        this.error = true;
        this.loading = false;
      } else {
        this.loading = false;
      }
    });
  },
  methods: {
    submit() {
      if (this.password1.length <= 0) {
        this.$toast.add({
          severity: 'error',
          summary: 'Vyplňte nové heslo.',
          life: 3000,
        });
        return;
      } else if (this.password2.length <= 0) {
        this.$toast.add({
          severity: 'error',
          summary: 'Zadejte nové heslo pro kontrolu i podruhé.',
          life: 3000,
        });
        return;
      } else if (this.password2 != this.password1) {
        this.$toast.add({
          severity: 'error',
          summary: 'Hesla se neshodují!',
          life: 3000,
        });
        return;
      }

      window.eventBus.emit('show-overlay-message', {
        text: 'Nastavuji nové heslo...',
      });

      User.setNewPassword(this.password1, this.$route.params.hash).then(() => {
        window.eventBus.emit('hide-overlay-message');
        this.sent = true;
      });
    },
  },
};
</script>

<style lang="scss"></style>
