<template>
  <div class="parameters">
    <Badge value="RC" class="badge--small badge--red" v-if="card.isRookie" />
    <Badge
      :value="card.limit"
      class="badge--small badge--orange"
      v-if="card.limit"
    />
    <Badge
      value="Jersey"
      class="badge--small badge--green"
      v-if="card.isJersey"
    />
    <Badge
      value="Patch"
      class="badge--small badge--green"
      v-if="card.isPatch"
    />
    <Badge
      value="Memorabilie"
      class="badge--small badge--green"
      v-if="card.isRelic"
    />
    <Badge
      value="Podpis"
      class="badge--small badge--yellow"
      v-if="card.isSigned"
    />
    <Badge
      value="Checklist"
      class="badge--small"
      v-if="card.isChecklist"
    />
    <Badge value="Gradovaná" class="badge--small" v-if="card.isGraded" />
  </div>
</template>

<script>
import Badge from 'primevue/badge';

export default {
  props: ['card'],
  components: {
    Badge
  }
};
</script>

<style lang="scss" scoped>
.parameters {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
</style>
