export default [
  {
    text: 'Ceny karet',
    to: 'watch-cards-list',
    icon: 'money-bill-trend-up',
    subitems: [
      {
        text: 'Sledované RSS feedy',
        to: 'watch-rss-list',
        icon: 'square-rss',
      },
      {
        text: 'Sledované karty',
        to: 'watch-rss-list',
        icon: 'card-heart',
      },
    ],
  },
  {
    text: 'Moje sbírka',
    to: 'collection-list',
    icon: 'cards-blank',
  },
  {
    text: 'Uživatelé',
    to: 'accounts-list',
    icon: 'users',
  },
];
