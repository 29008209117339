<template>
  <div class="page-head">
    <h1>Seznam karet</h1>
  </div>

  <p>Ve sbírce máš aktuálně {{ cards.length }} karet!</p>

  <div class="action-buttons">
    <router-link :to="{ name: 'collection-new' }" class="p-button">Přidat novou kartu</router-link>
  </div>

  <div class="cards-list">
    <div class="filter-wrapper">
      <cards-filter @filter-updated="onFilter" />
    </div>

    <div class="box">
      <DataTable
        :value="cards"
        :paginator="true"
        :rows="lazyParams.perPage"
        responsiveLayout="scroll"
        :loading="loading"
        :totalRecords="totalObjects"
        @page="onPageChange($event)"
      >
        <template #loading>Načítám karty...</template>
        <template #empty>Žádná karta neodpovídá zvoleným parametrům.</template>

        <Column>
          <template #body="{ data }">
            <div v-if="data.mainImage" class="preview">
              <Image :src="data.mainImage.file.main" alt="Image Text" imageClass="preview__image" preview />
            </div>
          </template>
        </Column>

        <Column>
          <template #body="{ data }">
            <router-link :to="{ name: 'collection-detail', params: { id: data.id } }" class="card__name">
              {{ data.season.name }}
              {{ data.manufacturer.name }}
              {{ data.set }}
              {{ data.subset }}
              {{ formatCardNumber(data.number) }}
            </router-link>

            <div class="card__details">
              <span>
                {{ formatDate(data.dateAcquire) }}
              </span>
              <Parameters :card="data" />
            </div>
          </template>
        </Column>

        <Column>
          <template #body="{ data }">
            <div class="table--actions">
              <Button @click="duplicate(data.id)" icon="pi pi-clone" class="p-button-secondary" />
              <Button v-if="data.comcUrl" @click="visitCard(data)" icon="pi pi-external-link" class="p-button-secondary p-button--comc" />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>  
  </div>
</template>

<script>
import { Cards } from '@/services/repositories/Collection';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Parameters from '../components/CardParameters.vue';
import formatDateMixin from '@/mixins/formatDateMixin.js';
import Image from 'primevue/image';
import CardsFilter from '../components/filter/CardsFilter.vue';

export default {
  data() {
    return {
      cards: [],
      loading: true,
      filter: {
        season: null,
        set: null,
        subset: null,
        fulltext: null,
      },
      lazyParams: {
        page: 0,
        perPage: 20,
      },
      totalObjects: 0,
    };
  },
  mixins: [formatDateMixin],
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;

      let params = {
        page: this.lazyParams.page,
        perPage: this.lazyParams.perPage,
        ...this.filter,
      };

      Cards.get(params).then((data) => {
        this.cards = data.cards;
        this.totalObjects = data.total;
        this.loading = false;
      });
    },
    onPageChange(e) {
      this.lazyParams.page = e.page;
    },
    onFilter(filter) {
      this.filter = filter;
      this.loadData();
    },
    formatCardNumber(number) {
      if (!isNaN(number)) {
        return '#' + number;
      } else {
        return number;
      }
    },
    duplicate(cardId) {
      Cards.duplicate(cardId).then(() => {
        this.loadData();
      });
    },
    uniqueHelper(value, index, self) {
      return self.indexOf(value) === index;
    },
    visitCard(card) {
      window.open(card.comcUrl, '_blank').focus();
    }
  },
  components: {
    DataTable,
    Column,
    Button,
    InputText,
    Parameters,
    Dropdown,
    Image,
    CardsFilter,
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.cards-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  padding-top: var(--gap);
}
.card {
  &__name {
    font-weight: 700;
  }
  &__details {
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 15px;
  }
}
</style>

<style lang="scss">
.p-image {
  --size: 80px;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
}
.preview {
  &__image {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
}
</style>
