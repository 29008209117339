import Profile from '../views/Profile';

export default [
  {
    name: 'profile',
    path: '/muj-ucet',
    components: { mainContent: Profile },
    meta: { title: 'Můj profil' },
  },
];
