<template>
  <template v-if="!loading">
    <Form :fields="fields" :onSubmit="submit" />
  </template>
</template>

<script>
import { RssFeeds } from '@/services/repositories/Watching';
import Form from '@/components/form/Form';
import MainInfoFields from '../services/FeedFields';

export default {
  props: {
    feed: Object,
  },
  data() {
    return {
      fields: MainInfoFields,
      loading: true,
    };
  },
  mounted() {
    if (this.feed) {
      this.fields.setValuesFromEntity(this.feed);
    } else {
      this.fields.reset();
    }
    this.loading = false;
  },
  methods: {
    submit(values) {
      if (this.feed) {
        values.id = this.feed.id;
      }

      RssFeeds.save(values).then((response) => {
        if (response.result == 'ok') {
          window.eventBus.emit('success-toast', 'Uloženo.');

          if (!this.feed) {
            window.eventBus.emit('feed-created', response.id);
          }
        } else {
          window.eventBus.emit('error-toast', 'Nepodařilo se uložit!');
        }
      });
    },
  },
  components: {
    Form,
  },
};
</script>

<style lang="scss" scoped></style>
