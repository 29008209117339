<template>
  <div class="picker">
    <span class="picker__label">od</span>
    <TimePicker :default="from" name="from" @change="validateFrom" />
    <span class="picker__label">do</span>
    <TimePicker :default="to" name="to" @change="validateTo" />
    <span class="picker__error" v-if="!isValid"> Neplatný interval </span>
  </div>
</template>

<script>
import TimePicker from './TimePicker.vue';

export default {
  emits: ['interval-change'],
  props: {
    default: {
      type: String,
      default: null,
    },
    name: {
      type: String,
    },
  },
  data() {
    return {
      from: '',
      to: '',
      isValid: true,
    };
  },
  mounted() {
    if (this.default) {
      const parts = this.default.split('-');
      this.from = parts[0];
      this.to = parts[1];
    }
  },
  methods: {
    validateFrom(input) {
      this.from = input.value;
      this.validate();
    },
    validateTo(input) {
      this.to = input.value;
      this.validate();
    },
    validate() {
      const from = this.toDateObject(this.from);
      const to = this.toDateObject(this.to);

      if (from >= to) {
        this.isValid = false;

        this.$emit('interval-change', {
          name: this.name,
          value: null,
        });

        return;
      }

      this.isValid = true;

      this.$emit('interval-change', {
        name: this.name,
        value: this.value,
      });
    },
    toDateObject(string) {
      const parts = string.split(':');
      const time = new Date();
      time.setHours(parseInt(parts[0]), parseInt(parts[1]), 0);
      return time;
    },
  },
  components: {
    TimePicker,
  },
};
</script>

<style lang="scss" scoped>
.picker {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  &__error {
    color: #dc3545;
  }
}
</style>
