<template>
  <div class="page">
    <TopBar />
    <SideBar />
    <div class="page__content">
      <div class="decoration">
        <Logo />
      </div>
      <div class="main-content">
        <router-view name="mainContent" v-slot="{ Component, route }">
          <transition name="fade" mode="out-in">
            <div :key="route.name">
              <component :is="Component" />
            </div>
          </transition>
        </router-view>
      </div>
    </div>
    <div class="page__footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import TopBar from './topbar/TopBar';
import SideBar from './SideBar';
import Footer from './Footer';
import Logo from './Logo';

export default {
  components: {
    TopBar,
    SideBar,
    Footer,
    Logo,
  },
};
</script>

<style lang="scss" scoped>
.page {
  min-height: 100vh;
  padding-left: var(--sidebar-width);
  position: relative;
  display: flex;
  flex-direction: column;
  &__content {
    padding: 0 var(--spacing) 150px var(--spacing);
    position: relative;
    min-height: 100vh;
    overflow-x: hidden;
  }
  &__footer {
    padding-top: 50px;
    margin-top: auto;
  }
  .decoration {
    position: absolute;
    bottom: 0;
    right: -150px;
    aspect-ratio: 1/1;
    width: 40vw;
    z-index: 1;
    opacity: 0.05;
  }
  .main-content {
    position: relative;
    z-index: 2;
    min-height: 70vh;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
