<template>
  <div class="page-head">
    <h1>Sledované RSS feedy</h1>
    <div class="page-head__actions">
      <Button
        @click="$router.push({ name: 'watch-rss-new' })"
        label="Přidat nový"
        icon="pi pi-plus"
      />
    </div>
  </div>

  <div class="box">
    <DataTable :value="feeds" dataKey="id" :rowHover="true" :loading="loading">
      <template #loading> Načítám feedy... </template>
      <template #empty> Žádné uložené RSS feedy. </template>

      <Column field="name" header="Jméno">
        <template #body="{ data }">
          <router-link
            :to="{ name: 'watch-rss-detail', params: { id: data.id } }"
          >
            {{ data.name }}
          </router-link>
        </template>
      </Column>

      <Column header="Akce" style="width: 1%">
        <template #body="{ data }">
          <div class="table--actions">
            <Button @click="analyse(data)" label="Parsovat" icon="pi pi-sync" />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { RssFeeds } from '@/services/repositories/Watching';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';

export default {
  data() {
    return {
      feeds: [],
      loading: true,
    };
  },
  mounted() {
    RssFeeds.getAll().then((data) => {
      this.feeds = data;
      this.loading = false;
    });
  },
  methods: {
    analyse(feed) {
      window.eventBus.emit('show-overlay-message', { text: 'Analyzuji...' });

      RssFeeds.parse(feed).then((response) => {
        window.eventBus.emit('hide-overlay-message');

        if (response.result == 'error') {
          window.eventBus.emit('error-toast', response.message);
        } else {
          window.eventBus.emit('success-toast', 'Analyzováno.');
        }
      });
    },
  },
  components: {
    DataTable,
    Button,
    Column,
  },
};
</script>

<style lang="scss" scoped>
.image {
  &__thumb {
    img {
      --size: 50px;
      width: var(--size);
      height: var(--size);
      object-fit: cover;
    }
  }
}
</style>
