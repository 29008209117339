<template>
  <template v-if="loading"> Načítám kartu... </template>
  <template v-else>
    <div class="page-head">
      <h1 v-if="card">
        Karta
        <span class="ft">
          {{ card.season.name }}
          {{ card.manufacturer.name }}
          {{ card.set }}
          {{ formatCardNumber(card.number) }}
          {{ card.subset }}
        </span>
      </h1>

      <h1 v-if="!card">Nová karta</h1>
    </div>

    <div class="page-head__actions">
      <Button
          label="Zpět"
          @click="$router.push({ name: 'collection-list' })"
          class="p-button-secondary"
          icon="pi pi-arrow-left"
        />
        <Button
          label="COMC"
          @click="visitCard"
          class="p-button-secondary"
          icon="pi pi-external-link"
          v-if="card && card.comcUrl"
        />
        <Button
          label="Smazat"
          @click="remove"
          class="p-button-secondary"
          icon="pi pi-trash"
          v-if="card"
        />
    </div>

    <TabView v-model:activeIndex="activeTab">
      <TabPanel header="Přehled" v-if="card">
        <h2>Přehled</h2>
        <Overview :card="card" />
      </TabPanel>
      <TabPanel header="Vývoj ceny" v-if="card">
        <h2>Vývoj ceny</h2>
        <div class="prices-wrapper">
          <price-chart :card="card" />
          <prices-list :card="card" />
        </div>
      </TabPanel>
      <TabPanel header="Editace karty">
        <h2>Editace karty</h2>
        <MainInfoEdit :card="card" />
      </TabPanel>
      <TabPanel header="Galerie" :disabled="card ? false : true">
        <GalleryUpload :card="card" v-if="card" />
        <GalleryList :card="card" v-if="card" />
      </TabPanel>
    </TabView>
  </template>
</template>

<script>
import { Cards } from '@/services/repositories/Collection';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Button from 'primevue/button';
import MainInfoEdit from '../components/MainInfoEdit';
import GalleryUpload from '../components/GalleryUpload';
import GalleryList from '../components/GalleryList';
import Overview from '../components/Overview';
import PriceChart from '../components/PriceChart.vue';
import PricesList from '../components/PricesList.vue';

export default {
  data() {
    return {
      card: null,
      loading: true,
      activeTab: 0
    };
  },
  mounted() {
    this.initData();

    window.eventBus.on('card-updated', data => {
      this.initData(data);
    });
  },
  unmounted() {
    window.eventBus.off('card-updated');
  },
  watch: {
    $route(to, from) {
      if (to.name == from.name) {
        this.initData();
      }
    }
  },
  methods: {
    formatCardNumber(number) {
      if (!isNaN(number)) {
        return '#' + number;
      } else {
        return number;
      }
    },
    initData(cardId) {
      this.loading = true;
      cardId = cardId || this.$route.params.id;

      if (cardId) {
        Cards.getById(cardId).then(response => {
          this.card = response;
          this.loading = false;
          document.title = this.card.name;
        });
      } else {
        this.loading = false;
        this.card = null;
        document.title = 'Nová karta';
      }
    },
    visitCard() {
      window.open(this.card.comcUrl, '_blank').focus();
    },
    remove() {
      this.$confirm.require({
        message: 'Opravdu chcete smazat vrstvu ' + this.card.name + '? Veškeré objekty uložené v této vrstvě budou nenávratně odstraněny. Rovněž budou odstraněny všechny podvrstvy.',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          Cards.remove(this.card.id).then(() => {
            this.$toast.add({
              severity: 'success',
              summary: 'Karta ' + this.card.name + ' byla odstraněna.',
              life: 3000
            });
            this.$router.push({ name: 'collection-list' });
          });
        }
      });
    }
  },
  components: {
    GalleryUpload,
    GalleryList,
    TabView,
    TabPanel,
    MainInfoEdit,
    Overview,
    Button,
    PriceChart,
    PricesList
  }
};
</script>

<style lang="scss" scoped>
.prices-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
</style>
