<template>
  <Draggable
    v-model="images"
    @start="drag = true"
    @end="drag = false"
    item-key="id"
    v-bind="dragOptions"
    @update="saveOrder(images)"
  >
    <template #item="{element}">
      <div class="image box">
        <div class="image__handle">
          <Icon name="arrows-up-down" />
        </div>
        <div class="image__content">
          <div class="image__thumb-wrapper">
            <Image
              :src="element.url + '?v=' + new Date().getTime()"
              imageClass="gallery-image__thumb"
              preview
            />
          </div>
          <div class="image__actions">
            <Button
              @click="remove(element.id)"
              class="p-button-text"
              icon="pi pi-trash"
            />
          </div>
        </div>
      </div>
    </template>
  </Draggable>
</template>

<script>
import Draggable from 'vuedraggable';
import Button from 'primevue/button';
import { Cards } from '@/services/repositories/Collection';
import Icon from '@/components/icons/Icon';
import Image from 'primevue/image';

export default {
  props: ['card'],
  data() {
    return {
      images: [],
      drag: false,
      order: null,
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    };
  },
  created() {
    window.eventBus.on('image-added', image => {
      this.addImage(image.file.main, image.id);
    });
  },
  mounted() {
    for (var i in this.card.images) {
      this.addImage(
        this.card.images[i].file.main,
        this.card.images[i].id
      );
    }
  },
  methods: {
    addImage(url, id) {
      this.images.push({ url: url, id: id });
    },
    remove(id) {
      this.$confirm.require({
        message: 'Opravdu chcete smazat tento obrázek?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          Cards.Images.remove(id).then(() => {
            this.$toast.add({
              severity: 'success',
              summary: 'Obrázek byl odstraněn.',
              life: 3000
            });

            this.images.forEach((image, i) => {
              if (image.id == id) {
                this.images.splice(i, 1);
              }
            });
          });
        }
      });
    },
    saveOrder(list) {
      var order = [];

      for (var i = 0; i < list.length; i++) {
        order.push({
          id: list[i].id,
          order: i
        });
      }

      Cards.Images.saveOrder(order).then(() => {
        this.$toast.add({
          severity: 'success',
          summary: 'Pořadí aktualizováno.',
          life: 3000
        });
      });
    }
  },
  components: {
    Draggable,
    Button,
    Image,
    Icon
  }
};
</script>

<style lang="scss">
.image__thumb-wrapper {
  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
  span {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="scss" scoped>
.image {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: var(--gap);
  &__handle {
    --size: 20px;
    width: var(--size);
    height: var(--size);
    cursor: grab;
    color: var(--primary-color);
    &:hover {
      color: var(--secondary-color);
    }
  }
  &__content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &__thumb-wrapper {
    width: 100px;
    height: 50px;
  }
  &__actions {
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}
</style>
