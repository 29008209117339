export default {
  state: {
    savedPages: {},
  },
  mutations: {
    saveCurrentPage(state, data) {
      state.savedPages[data.table] = data.page;
    },
    unsaveCurrentPage(state, data) {
      if (state.savedPages[data.table]) {
        state.savedPages[data.table] = null;
      }
    },
  },
  getters: {
    getSavedPages: (state) => {
      return state.savedPages;
    },
  },
};
