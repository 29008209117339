import moment from 'moment';

export default {
  methods: {
    formatDateTime(date) {
      moment.locale('cs');
      return moment(String(date)).format('llll');
    },
    formatDate(date) {
      moment.locale('cs');
      return moment(String(date)).format('D.M.YYYY');
    },
    formateTimeRange(timeFrom, timeTo) {
      moment.locale('cs');
      return (
        moment(timeFrom).format('HH:mm')
        + '-' +
        moment(timeTo).format('HH:mm')
      );
    },
    formateTime(time) {
      console.log(time);
      moment.locale('cs');
      return moment(time).format('HH:mm')
    }
  }
};
