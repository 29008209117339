import items from './items';
import { store } from '@/store/store.js';

const mainMenuItemsService = {
  user: null,

  get: function () {
    return this.filter();
  },

  filter: function () {
    this.user = store.getters.getUser;
    var toShow = [];

    items.forEach((item) => {
      if (!item.rights) {
        toShow.push(item);
      } else {
        for (let right of item.rights) {
          if (this.isAllowed(right)) {
            toShow.push(item);
            break;
          }
        }
      }
    });

    return toShow;
  },

  isAllowed(key) {
    var is = false;

    for (let right of this.user.rights) {
      if (right.key == key) {
        is = true;
        break;
      }
    }

    return is;
  },
};

export { mainMenuItemsService };
