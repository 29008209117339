import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js';
import ToastService from 'primevue/toastservice';
import 'primevue/resources/themes/bootstrap4-light-purple/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import '@/scss/customProperties.scss';
import '@/scss/atoms/atoms.scss';
import mitt from 'mitt';
import { store } from './store/store.js';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';

const confirmationSettings = {
  locale: {
    accept: 'Ano',
    reject: 'Ne',
  },
};

window.eventBus = mitt();

const app = createApp(App);
app.directive('tooltip', Tooltip);
app.use(router);
app.use(PrimeVue, confirmationSettings);
app.use(ToastService);
app.use(store);
app.use(ConfirmationService);
app.mount('#app');
