<template>
  <div class="footer">
    <div class="footer__meta">
      &copy; DSO Severovýchod
      <span class="footer__delimiter"></span>
      verze {{ version }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      version: process.env.PACKAGE_VERSION,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  text-align: right;
  padding: var(--spacing);
  font-size: 12px;
  font-weight: 300;
  &__delimiter {
    width: 1px;
    height: 15px;
    background: #ccc;
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 10px;
  }
}
</style>
