<template>
  <div class="icon">
    <FontAwesomeIcon :icon="iconParams" />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faArrowRightFromBracket as falArrowRightFromBracket,
  faArrowTrendDown as falArrowTrendDown,
  faArrowTrendUp as falArrowTrendUp,
  faArrowsUpDown as falArrowsUpDown,
  faCardsBlank as falCardsBlank,
  faCardHeart as falCardHeart,
  faCircleQuestion as falCircleQuestion,
  faCoffee as falCoffee,
  faHockeySticks as falHockeySticks,
  faChevronDown as falChevronDown,
  faKey as falKey,
  faMoneyBillTrendUp as falMoneyBillTrendUp,
  faSquareInfo as falSquareInfo,
  faSquareRss as falSquareRss,
  faUser as falUser,
  faUsers as falUsers,
} from '@fortawesome/pro-light-svg-icons';

library.add(
  falArrowRightFromBracket,
  falArrowTrendDown,
  falArrowTrendUp,
  falArrowsUpDown,
  falCardHeart,
  falCardsBlank,
  falCircleQuestion,
  falCoffee,
  falHockeySticks,
  falChevronDown,
  falKey,
  falMoneyBillTrendUp,
  falSquareInfo,
  falSquareRss,
  falUsers,
  falUser,
);

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    weight: {
      type: String,
      default: 'light',
    },
    brand: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconParams() {
      if (this.brand) {
        return 'fa-brands fa-' + this.name;
      } else {
        return 'fa-' + this.weight + ' fa-' + this.name;
      }
    }
  },
  components: {
    FontAwesomeIcon
  }
};
</script>

<style lang="scss" scoped></style>
