<template>
  <div class="dashboard">
    <div class="menu">
      <router-link
        :to="{ name: item.to }"
        class="card"
        v-for="item in items"
        :key="item.to"
      >
        <span class="card__icon">
          <Icon :name="item.icon" />
        </span>
        <span class="card__decoration">
          <Icon :name="item.icon" />
        </span>
        <span class="card__text">
          {{ item.text }}
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icons/Icon';
import { mainMenuItemsService } from '@/services/menu/mainMenuItemsService';
import config from '@/config.js';

export default {
  data() {
    return {
      items: mainMenuItemsService.get(),
      company: config.company.name,
    };
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  padding-top: 100px;
}
.menu {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: var(--gap);
}
.card {
  --color: var(--primary-color);

  border-radius: var(--radius);
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  padding: 30px;
  background: #fff;
  color: var(--color);
  box-shadow: 2px 2px 20px rgba(#000, 0.04);
  position: relative;
  overflow: hidden;
  &__icon {
    --size: 30px;
    width: var(--size);
    height: var(--size);
    * {
      fill: currentColor;
    }
  }
  &__decoration {
    --size: 150px;
    width: var(--size);
    height: var(--size);
    position: absolute;
    right: -20px;
    top: -20%;
    transform: rotate(20deg);
    opacity: 0.03;
    * {
      fill: currentColor;
    }
  }
  &__text {
    font-weight: 700;
    color: var(--color);
    text-transform: uppercase;
  }
  &:hover {
    --color: var(--secondary-color);
  }
}
</style>
