import { Rules } from '@/services/form/Rules';
import { FieldTypes } from '@/services/form/FieldTypes';

let form = {
  fields: {
    email: {
      value: '',
      name: 'email',
      type: FieldTypes.text,
      label: 'E-mail',
      defaultValue: '',
      rules: [
        {
          type: Rules.required,
          message: 'Vyplňte prosím e-mail.',
        },
        {
          type: Rules.email,
          message: 'Vyplňte prosím e-mail.',
        },
      ],
    },
    name: {
      value: '',
      name: 'name',
      type: FieldTypes.text,
      label: 'Příjmení a jméno',
      defaultValue: '',
      rules: [
        {
          type: Rules.required,
          message: 'Vyplňte prosím jméno.',
        },
      ],
    },
    password: {
      value: '',
      name: 'password',
      type: FieldTypes.password,
      label: 'Heslo',
      defaultValue: '',
      rules: [
        {
          type: Rules.minLength,
          message: 'Heslo musí obsahovat alespoň 5 znaků.',
          length: 5,
        },
        {
          type: Rules.sameAs,
          message: 'Hesla se neshodují.',
          sameAs: 'password2',
        },
      ],
    },
    password2: {
      value: '',
      name: 'password2',
      type: FieldTypes.password,
      label: 'Heslo znovu',
      defaultValue: '',
    },
    active: {
      name: 'active',
      label: 'Aktivní?',
      type: FieldTypes.checkbox,
      value: 1,
      defaultValue: 1,
    },
    submit: {
      text: 'Uložit',
      name: 'submit',
      type: FieldTypes.submit,
    },
  },
  get() {
    return this.fields;
  },
  setValue(field, value) {
    this.fields[field].value = value;

    if (field == 'password') {
      this.setValue('password2', value);
    }
  },
  setValuesFromEntity(account) {
    this.setValue('email', account.email);
    this.setValue('name', account.name);
    this.setValue('active', account.active == 1);
  },
  getValues() {
    return {
      email: this.fields.email.value,
      name: this.fields.name.value,
      password: this.fields.password.value,
      active: this.fields.active.value,
    };
  },
  reset() {
    for (var name in this.fields) {
      let field = this.fields[name];

      if (field.defaultValue === 'undefined') {
        continue;
      }

      field.value = field.defaultValue;
    }
  },
};

export default form;
