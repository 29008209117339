<template>
  <div class="graph">
    <Line :options="settings" :data="chartData" />
  </div>
</template>

<script>
import formatPriceMixin from '@/mixins/formatPriceMixin.js';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { Line } from 'vue-chartjs';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  CategoryScale,
  Filler,
  LinearScale
);
export default {
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      settings: {
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
          },
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const formatter = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                });

                return formatter.format(context.parsed.y);
              },
            },
          },
        },
      },
    };
  },
  mixins: [formatPriceMixin],
  computed: {
    chartData() {
      let labels = this.card.prices.map((price) => {
        return price.date.substring(0, 10);
      });

      let datasets = this.card.prices.map((price) => {
        return price.price;
      });

      return {
        labels: labels,
        datasets: [
          {
            data: datasets,
            backgroundColor: '#fce414',
            borderColor: '#141c24',
            tension: 0.1,
            fill: true,
          },
        ],
      };
    },
  },
  components: {
    Line,
  },
};
</script>

<style lang="scss" scoped>
</style>
