<template>
  <span class="p-input-icon-right">
    <i class="pi pi-clock" />
    <InputMask
      v-model="value"
      mask="99:99"
      placeholder="12:00"
      slotChar="HH:MM"
      :class="['input', !isValid ? 'p-invalid' : null]"
      @keypress="validate"
      @change="validate"
      @update="validate"
    />
  </span>
</template>

<script>
import InputMask from 'primevue/inputmask';

export default {
  emits: ['change'],
  props: {
    default: {
      type: String,
      default: null,
    },
    name: String,
  },
  data() {
    return {
      value: '',
      isValid: true,
    };
  },
  mounted() {
    if (this.default) {
      this.value = this.default;
    }
  },
  watch: {
    default() {
      if (this.default) {
        this.value = this.default;
      }
    },
  },
  methods: {
    validate() {
      const parts = this.value.split(':');
      if (!this.isNumeric(parts[0]) || !this.isNumeric(parts[1])) {
        this.isValid = false;
        return;
      }

      const hours = parseInt(parts[0]);
      const minutes = parseInt(parts[1]);

      if (hours > 24 || hours < 0 || minutes > 59 || minutes < 0) {
        this.isValid = false;
        return;
      }

      this.isValid = true;

      this.$emit('change', {
        name: this.name,
        value: this.value,
      });
    },
    isNumeric(value) {
      return !isNaN(value) && !isNaN(parseFloat(value));
    },
  },
  components: {
    InputMask,
  },
};
</script>

<style lang="scss" scoped>
.input {
  width: 100px;
}
</style>
