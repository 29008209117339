<template>
  <h1 class="heading heading--public tc cw">Nová registrace</h1>

  <template v-if="!sent">
    <form v-on:submit.prevent="submit" class="form form--public">
      <div class="public-field-wrapper">
        <InputText
          type="text"
          v-model="data.name"
          placeholder="Příjmení a jméno"
          class="form__field"
        />
      </div>
      <div class="public-field-wrapper">
        <InputText
          type="text"
          v-model="data.email"
          placeholder="Váš e-mail"
          class="form__field"
        />
      </div>
      <div class="public-field-wrapper">
        <Password
          v-model="data.password1"
          placeholder="Heslo"
          class="form__field"
          :feedback="false"
          toggleMask
        />
      </div>

      <div class="public-field-wrapper">
        <Password
          v-model="data.password2"
          placeholder="Heslo znovu"
          :feedback="false"
          class="form__field"
          toggleMask
        />
      </div>

      <Button class="p-button--xxl p-button--black" @click="submit">
        Odeslat
      </Button>
    </form>
  </template>

  <p v-if="sent" class="message">
    Děkujeme za Vaši registraci! Na váš e-mail jsme odeslali odkaz pro její
    dokončení.
  </p>

  <div class="bottom-links">
    <router-link :to="{ name: 'login' }" class="bottom-links__link">
      zpět
    </router-link>
  </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { Registration } from '@/services/User';
import Password from 'primevue/password';

export default {
  data() {
    return {
      data: {
        name: '',
        email: '',
        password1: '',
        password2: '',
      },
      loading: true,
      sent: false,
    };
  },
  components: {
    Button,
    Password,
    InputText,
  },
  methods: {
    submit() {
      if (
        this.data.name.length <= 0 ||
        this.data.email.length <= 3 ||
        this.data.password1.length <= 0
      ) {
        window.eventBus.emit('error-toast', 'Vyplňte prosím všechna pole.');
      } else if (this.data.password1 != this.data.password2) {
        window.eventBus.emit('error-toast', 'Hesla se neshodují.');
      } else {
        window.eventBus.emit('show-overlay-message', {
          text: 'Vytvářím nový účet...',
        });

        Registration.create(this.data).then((response) => {
          window.eventBus.emit('hide-overlay-message');
          if (response.result == 'failed') {
            window.eventBus.emit('error-toast', response.reason);
          } else {
            this.sent = true;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.message {
  color: var(--primary-color);
  text-align: center;
}
</style>
