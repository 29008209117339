import List from '../views/List';
import Detail from '../views/Detail';

const BASE_NAME = 'collection-';
const BASE_URL = '/';

export default [
  {
    name: BASE_NAME + 'list',
    path: BASE_URL + 'moje-sbirka',
    components: { mainContent: List },
    meta: { title: 'Seznam karet' }
  },
  {
    name: BASE_NAME + 'new',
    path: BASE_URL + 'moje-sbirka/nova',
    components: { mainContent: Detail }
  },
  {
    name: BASE_NAME + 'detail',
    path: BASE_URL + 'moje-sbirka/:id',
    components: { mainContent: Detail }
  }

];
