<template>
  <div class="page-head">
    <h1>Sledované karty</h1>
  </div>

  <div class="box">
    <DataTable
      :value="cards"
      :lazy="true"
      :paginator="true"
      :rows="10"
      v-model:filters="filters"
      :rowHover="true"
      ref="dt"
      dataKey="id"
      :totalRecords="totalRecords"
      :loading="loading"
      @page="onPage($event)"
      @filter="onFilter($event)"
      :globalFilterFields="['name']"
      responsiveLayout="scroll">

      <template #header>
        <div class="header">
          <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
          <Button icon="pi pi-search" class="p-ml-1" @click="filterGlobal" />
        </div>
      </template>

      <template #loading>Načítám karty...</template>
      <template #empty>Žádné uložené karty.</template>

      <Column field="image">
        <template #body="{ data }">
          <router-link
            :to="{ name: 'watch-cards-detail', params: { id: data.id } }"
            v-if="data.image"
            class="image"
          >
            <img :src="data.image" />
          </router-link>
        </template>
      </Column>

      <Column field="name">
        <template #body="{ data }">
          <router-link
            :to="{ name: 'watch-cards-detail', params: { id: data.id } }"
            class="name"
          >
            {{ data.name }}
          </router-link>
        </template>
      </Column>

      <Column field="Poslední cena">
        <template #body="{ data }">
          <span v-if="data.lastPrice">
            ${{ data.lastPrice.price }}
          </span>
        </template>
      </Column>

      <Column field="Trend">
        <template #body="{ data }">
          <span v-if="data.trend" :class="[ 'trend', data.trend > 0 ? 'trend--positive' : null, data.trend < 0 ? 'trend--negative' : null ]">
            <span class="trend__icon" v-if="data.trend > 0">
              <Icon name="arrow-trend-up" />
            </span>
            <span class="trend__icon" v-if="data.trend < 0">
              <Icon name="arrow-trend-down" />
            </span>
            {{ data.trend }} %
          </span>
        </template>
      </Column>

      <Column style="width: 1%">
        <template #body="{ data }">
          <div class="table--actions">
            <Button
              @click="
                $router.push({
                  name: 'watch-cards-detail',
                  params: { id: data.id },
                })
              "
              icon="pi pi-search-plus"
            />
            <Button @click="visitCard(data)" icon="pi pi-external-link" />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { Cards } from '@/services/repositories/Watching';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import { FilterMatchMode } from 'primevue/api';
import InputText from 'primevue/inputtext';
import Icon from '@/components/icons/Icon';

export default {
  data() {
    return {
      cards: [],
      loading: true,
      totalRecords: 0,
      lazyParams: {
        page: 0,
        perPage: 10,
        globalFilter: null
      },
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  mounted() {
   this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;

      Cards.getAll(this.lazyParams).then((data) => {
        this.totalRecords = data.totalRecords;
        this.cards = data.cards;
        this.cards.forEach(card => this.decorateCard(card) );
        this.loading = false;
      });
    },
    onPage(event) {
      this.lazyParams.page = event.page;
      this.loadData();
    },
    filterGlobal() {
      this.lazyParams.globalFilter = this.filters.global.value;
      this.lazyParams.page = 0;

      this.loadData();
    },
    decorateCard(card) {
      card.lastPrice = null;
      card.trend = null;

      if (card.prices.length > 0) {
        card.lastPrice = card.prices[card.prices.length - 1];
      }

      if (card.prices.length > 1) {
        let lastButOne = card.prices[card.prices.length - 2];
        let diff = card.lastPrice.price - lastButOne.price;
        let percentage = ((diff/lastButOne.price) * 100).toFixed(2);

        card.trend = percentage;
      }
    },
    visitCard(card) {
      window.open('https://www.comc.com/Cards/a/a/a/a/a/' + card.comcId, '_blank').focus();
    }
  },
  components: {
    DataTable,
    Button,
    Column,
    InputText,
    Icon
  },
};
</script>

<style lang="scss" scoped>
.image {
  display: block;
  text-align: center;
  img {
    margin: 0 auto;
    --size: 50px;
    max-width: var(--size);
    max-height: var(--size);
    object-fit: contain;
  }
}
.trend {
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;

  &--negative {
    color: red;
  }
  &--positive {
    color: green;
  }

  &__icon {
    --size: 20px;
    max-width: var(--size);
    max-height: var(--size);
    display: block;
    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
