import Vuex from 'vuex';
import userStore from './userStore';
import dataTablePageStore from './dataTablePageStore';

export const store = new Vuex.Store({
  modules: {
    userStore,
    dataTablePageStore,
  },
});
