<template>
  <div class="logo"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.logo {
  svg {
    width: 104px;
    height: auto;
    max-width: 100%;
    display: block;
    margin: 20px 0;
  }
  * {
    fill: #fff;
  }
}
</style>
