<template>
  <div class="logo"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
svg {
  width: 272px;
}
</style>
