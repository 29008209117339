import axios from 'axios';

export const API = {
  get,
  post,
};

async function get(url, addToken = true) {
  var fullUrl = process.env.VUE_APP_API_URL;

  if (addToken) {
    fullUrl += localStorage.getItem('token') + '/';
  }

  fullUrl += url;

  const response = await axios.get(fullUrl).catch((e) => {
    handleError(e);
  });

  return response.data;
}

async function post(url, data, headers = {}, addToken = true) {
  var fullUrl = process.env.VUE_APP_API_URL;
  if (addToken) {
    fullUrl += localStorage.getItem('token') + '/';
  }

  fullUrl += url;

  const response = await axios({
    method: 'post',
    url: fullUrl,
    data: data,
    headers: headers,
  }).catch((e) => {
    handleError(e);
  });

  return response.data;
}

function handleError(error) {
  Promise.reject(error);
  window.eventBus.emit('error', error);
}
