<template>
  <div class="gallery" v-if="isOpen">
    <div class="gallery__close" @click="isOpen = false">
      <Icon name="xmark" />
    </div>
    <div class="gallery__arrows gallery__arrows--previous" @click="previous()">
      <Icon name="chevronLeft" />
    </div>
    <div class="gallery__arrows gallery__arrows--next" @click="next()">
      <Icon name="chevronRight" />
    </div>
    <div class="gallery__content">
      <img :src="currentMedium.file.main" class="gallery__image" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icons/Icon';

export default {
  data() {
    return {
      currentIndex: 0,
      isOpen: false,
      media: [],
    };
  },
  computed: {
    currentMedium() {
      return this.media[this.currentIndex];
    },
  },
  created() {
    window.eventBus.on('gallery-open', (data) => {
      this.media = data.media;
      this.currentIndex = data.index;
      this.isOpen = true;
    });
  },
  methods: {
    next() {
      if (this.currentIndex + 1 == this.media.length) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
    },
    previous() {
      if (this.currentIndex == 0) {
        this.currentIndex = this.media.length - 1;
      } else {
        this.currentIndex--;
      }
    },
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  --side-size: 70px;
  z-index: 1002;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#000, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    --size: var(--side-size);
    width: var(--size);
    height: var(--size);
    color: #fff;
    padding: calc(var(--size) / 4);
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }
  }
  &__content {
    width: calc(100% - 2 * var(--side-size));
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__image {
    max-width: 100%;
    max-height: 100%;
  }
  &__arrows {
    --size: var(--side-size);
    width: var(--size);
    height: var(--size);
    position: absolute;
    top: calc(50% - var(--size) / 2);
    color: #fff;
    padding: calc(var(--size) / 4);
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }
    &--previous {
      left: 0;
    }
    &--next {
      right: 0;
    }
  }
}
</style>
