<template>
  <div class="page-head">
    <h1>Můj profil</h1>
  </div>

  <div class="grid">
    <div class="box">
      <div class="box__head">
        <div class="box__head-icon">
          <Icon name="square-info" />
        </div>
        Moje údaje
      </div>
      <BasicInfo />
    </div>
    <div class="box">
      <div class="box__head">
        <div class="box__head-icon">
          <Icon name="key" />
        </div>
        Změna hesla
      </div>
      <Password />
    </div>
  </div>
</template>

<script>
import Password from '../components/Password.vue';
import BasicInfo from '../components/BasicInfo.vue';
import Icon from '@/components/icons/Icon';

export default {
  components: {
    Password,
    BasicInfo,
    Icon,
  },
  data() {
    return {
      user: this.$store.state.userStore.user,
    };
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap);
}
</style>
