import { API } from '@/services/API';

const BASE_URL = 'accounts/';

const Accounts = {
  getAll: async function () {
    return API.get(BASE_URL + 'get-all');
  },

  getById: async function (accountId) {
    return API.get(BASE_URL + 'get-by-id/' + parseInt(accountId));
  },

  save: async function (values) {
    return API.post(BASE_URL + 'save', values);
  },

  remove: async function (accountId) {
    return API.get(BASE_URL + 'remove/' + parseInt(accountId));
  },
};

const Rights = {
  url: BASE_URL + 'rights/',

  grant: async function (accountId, rightKey) {
    return API.get(this.url + 'grant/' + parseInt(accountId) + '/' + rightKey);
  },

  revoke: async function (accountId, rightKey) {
    return API.get(this.url + 'revoke/' + parseInt(accountId) + '/' + rightKey);
  },

  getAll: async function () {
    return API.get(this.url + 'get-all');
  },

  getCategories: async function () {
    return API.get(this.url + 'get-categories');
  },
};

export { Accounts, Rights };
