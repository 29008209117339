export const webpSupport = {
  detect,
};

async function detect() {
  localStorage.setItem('webp', false);

  if (!self.createImageBitmap) {
    return;
  }

  const webpData =
    'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';
  const blob = await fetch(webpData).then((r) => r.blob());

  this.webpSupported = await createImageBitmap(blob).then(
    () => {
      localStorage.setItem('webp', true);
    },
    () => {
      localStorage.setItem('webp', false);
    }
  );
}
