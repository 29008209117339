import { FieldTypes } from '@/services/form/FieldTypes';
import { Seasons, Manufacturers } from '@/services/repositories/Collection';

export default {
  fields: {
    date_acquire: {
      name: 'date_acquire',
      label: 'Datum získání',
      value: null,
      defaultValue: new Date(),
      type: FieldTypes.date
    },
    season: {
      value: '',
      name: 'season',
      type: FieldTypes.select,
      label: 'Sezona',
      options: []
    },
    manufacturer: {
      value: '',
      name: 'manufacturer',
      type: FieldTypes.select,
      label: 'Výrobce',
      options: []
    },
    set: {
      name: 'set',
      label: 'Set',
      value: null,
      defaultValue: '',
      type: FieldTypes.text,
      fullWidth: true
    },
    subset: {
      name: 'subset',
      label: 'Subset',
      value: null,
      defaultValue: '',
      type: FieldTypes.text,
      fullWidth: true
    },
    number: {
      name: 'number',
      label: 'Číslo karty',
      value: null,
      defaultValue: '',
      type: FieldTypes.text
    },
    is_jersey: {
      name: 'is_jersey',
      label: 'S kusem dresu?',
      value: false,
      defaultValue: false,
      type: FieldTypes.checkbox
    },
    is_patch: {
      name: 'is_patch',
      label: 'S patchem?',
      value: false,
      defaultValue: false,
      type: FieldTypes.checkbox
    },
    is_relic: {
      name: 'is_relic',
      label: 'S jinou memorabilií?',
      value: false,
      defaultValue: false,
      type: FieldTypes.checkbox
    },
    is_signed: {
      name: 'is_signed',
      label: 'Podepsaná?',
      value: false,
      defaultValue: false,
      type: FieldTypes.checkbox
    },
    is_graded: {
      name: 'is_graded',
      label: 'Gradovaná?',
      value: false,
      defaultValue: false,
      type: FieldTypes.checkbox
    },
    is_checklist: {
      name: 'is_checklist',
      label: 'Je to checklist?',
      value: false,
      defaultValue: false,
      type: FieldTypes.checkbox
    },
    limit: {
      name: 'limit',
      label: 'Limitace',
      value: null,
      defaultValue: '',
      type: FieldTypes.text
    },
    acquire_price: {
      name: 'acquire_price',
      label: 'Pořizovací cena',
      value: 0,
      defaultValue: 0,
      type: FieldTypes.money,
      locale: 'cs-CZ',
      currency: 'USD'
    },
    acquired_from: {
      name: 'acquired_from',
      label: 'Původ',
      value: null,
      defaultValue: '',
      type: FieldTypes.text
    },
    note: {
      name: 'note',
      label: 'Poznámka',
      value: null,
      defaultValue: '',
      type: FieldTypes.editor,
      fullWidth: true
    },
    comc_url: {
      name: 'comc_url',
      label: 'COMC url',
      value: null,
      defaultValue: '',
      type: FieldTypes.text,
      fullWidth: true
    },
    submit: {
      text: 'Uložit',
      name: 'submit',
      type: FieldTypes.submit
    }
  },
  async initFields() {
    const manufacturers = Manufacturers.getAll().then(data => {
      this.fields.manufacturer.options = data;
    });

    const seasons = Seasons.getAll().then(data => {
      this.fields.season.options = data;
    });

    return Promise.all([manufacturers, seasons]);
  },
  get() {
    return this.fields;
  },
  setValue(field, value) {
    this.fields[field].value = value;
  },
  setValuesFromEntity(card) {
    this.setValue('set', card.set);
    this.setValue('subset', card.subset);
    this.setValue('number', card.number);
    this.setValue('is_jersey', card.isJersey == 1);
    this.setValue('is_patch', card.isPatch == 1);
    this.setValue('is_relic', card.isRelic == 1);
    this.setValue('is_signed', card.isSigned == 1);
    this.setValue('is_graded', card.isGraded == 1);
    this.setValue('is_checklist', card.isChecklist == 1);
    this.setValue('limit', card.limit);
    this.setValue('acquire_price', card.acquirePrice);
    this.setValue('acquired_from', card.acquiredFrom);
    this.setValue('note', card.note);
    this.setValue('comc_url', card.comcUrl);

    if (card.dateAcquire) {
      let date = new Date(Date.parse(card.dateAcquire));
      this.setValue('date_acquire', date);
    }

    if (card.manufacturer) {
      this.setValue('manufacturer', card.manufacturer.id);
    } else {
      this.setValue('manufacturer', null);
    }

    if (card.season) {
      this.setValue('season', card.season.id);
    } else {
      this.setValue('season', null);
    }
  },
  getValues() {
    let values = {};

    for (var name in this.fields) {
      let field = this.fields[name];

      if (field.value === 'undefined') {
        continue;
      }

      values[field.name] = field.value;
    }

    return values;
  },
  reset() {
    for (var name in this.fields) {
      let field = this.fields[name];

      if (field.defaultValue === 'undefined') {
        continue;
      }

      field.value = field.defaultValue;
    }
  }
};
