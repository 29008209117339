<template>
  <div class="input">
    <div class="input__label">Sezona</div>
    <div class="input__input">
      <InputText v-model="q" @input="queryChanged" placeholder="Fulltext..." />
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';

export default {
  emits: ['fulltext-filled'],
  data() {
    return {
      q: '',
    };
  },
  methods: {
    queryChanged() {
      if (this.q.length < 3) {
        this.$emit('fulltext-filled', '');
      } else {
        this.$emit('fulltext-filled', this.q);
      }
    },
  },
  components: {
    InputText,
  },
};
</script>

<style lang="scss" scoped>
.input {
  display: flex;
  flex-direction: column;
  gap: 5px;
  &__label {
    font-size: 0.85rem;
  }
}
</style>
