<template>
  <h1 class="heading heading--public tc cw a a--fadeinbottom">Přihlášení</h1>

  <form
    v-on:submit.prevent="tryLogin"
    class="form--public a a--fadeinbottom a--delay-500"
  >
    <div class="public-field-wrapper">
      <InputText
        type="text"
        v-model="email"
        placeholder="E-mail"
        class="form__field"
        @keyup.enter="tryLogin"
      />
    </div>

    <div class="public-field-wrapper">
      <Password
        v-model="password"
        placeholder="Heslo"
        class="form__field"
        :feedback="false"
        @keyup.enter="tryLogin"
        toggleMask
      />
    </div>

    <Button class="p-button--xxl p-button--black" @click="tryLogin">
      Přihlásit se
    </Button>
  </form>

  <div class="bottom-links a a--fadeinbottom a--delay-900">
    <router-link
      :to="{ name: 'forgottenPassword' }"
      class="cb bottom-links__link"
    >
      zapomněl jsem heslo
    </router-link>
    <span class="bottom-links__delimiter"> • </span>
    <router-link
      :to="{ name: 'registration-new' }"
      class="cb bottom-links__link"
    >
      zaregistrovat se
    </router-link>
  </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import { User } from '@/services/User';

export default {
  data() {
    return {
      email: '',
      password: '',
      isCheckingLogin: false,
    };
  },
  components: {
    Button,
    InputText,
    Password,
  },
  mounted() {
    window.eventBus.on('login-failed', (info) => {
      if (info.reason == User.INVALID_TOKEN) {
        this.$toast.add({
          severity: 'error',
          summary: 'Vaše přihlášení vypršelo.',
          life: 3000,
        });
      }
    });
  },
  unmounted() {
    window.eventBus.off('login-failed');
  },
  methods: {
    tryLogin() {
      if (this.isCheckingLogin) {
        return;
      }

      this.isCheckingLogin = true;

      if (this.email.length <= 0) {
        this.$toast.add({
          severity: 'error',
          summary: 'Vyplňte přihlašovací e-mail.',
          life: 3000,
        });
        this.isCheckingLogin = false;
        return;
      }

      if (this.password.length <= 0) {
        this.$toast.add({
          severity: 'error',
          summary: 'Vyplňte heslo.',
          life: 3000,
        });
        this.isCheckingLogin = false;
        return;
      }

      window.eventBus.emit('show-overlay-message', {
        text: 'Kontroluji údaje...',
      });

      const credentials = {
        email: this.email,
        password: this.password,
      };

      User.authenticate(credentials).then((data) => {
        this.isCheckingLogin = false;
        window.eventBus.emit('hide-overlay-message');

        if (data.result == 'ok') {
          User.login(data.token, data.user);
          this.$router.push({ name: 'dashboard' });
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Neplatné přihlašovací údaje.',
            life: 3000,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
