<template>
  <div>
    <div
      :class="[
        'image',
        display ? display : 'circle',
        !uploadedImage && !image ? 'hidden' : null,
      ]"
    >
      <div class="image__wrapper">
        <img v-if="uploadedImage" :src="uploadedImage" />
        <img
          v-if="!uploadedImage && image"
          :src="image.absolutePath + image.relativePath"
        />
      </div>

      <Button
        @click="$refs.FileInput.click()"
        class="image__button p-button-secondary"
      >
        Vybrat ze zařízení
      </Button>
      <input ref="FileInput" type="file" @change="onFileSelect" />
    </div>

    <div v-if="showEditor">
      <VueCropper
        v-show="selectedFile"
        ref="cropper"
        :src="selectedFile"
        alt="Source Image"
        :aspect-ratio="aspectRatio || null"
      />
      <Button @click="saveImage(), (showEditor = false)"> Crop </Button>
      <Button @click="dialog = false"> Cancel </Button>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import Button from 'primevue/button';
import 'cropperjs/dist/cropper.css';

export default {
  props: ['image', 'name', 'display', 'onUpload', 'aspectRatio'],
  data() {
    return {
      mime_type: '',
      cropedImage: '',
      autoCrop: false,
      selectedFile: '',
      uploadedImage: '',
      uploadedImageFileName: '',
      showEditor: false,
      files: '',
    };
  },
  components: {
    VueCropper,
    Button,
  },
  methods: {
    saveImage() {
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData();
        formData.append(this.name, blob, 'name.jpg');
        this.onUpload(formData).then((response) => {
          this.uploadedImageFileName = response.image;
          this.uploadedImage =
            response.absPath +
            response.relativePath +
            response.image +
            response.ext;
          this.$emit('imageFinished', {
            name: this.name,
            src: this.uploadedImageFileName,
          });
        });
      }, this.mime_type);
    },
    onFileSelect(e) {
      const file = e.target.files[0];
      this.mime_type = file.type;
      if (typeof FileReader === 'function') {
        this.showEditor = true;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);
        };
        reader.readAsDataURL(file);
      } else {
        this.$toast.add({
          severity: 'error',
          summary:
            'Váš prohlížeč bohužel neposkytuje funkce potřebné pro editaci obrázku.',
          life: 3000,
        });
      }
    },
    getImage() {
      return this.uploadedImageFileName || this.image.fileName;
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  display: flex;
  align-items: flex-start;
  input {
    display: none;
  }
  &.circle {
    .image__wrapper {
      border-radius: 50%;
    }
    img {
      border-radius: 50%;
    }
  }
  &.hidden img {
    display: none;
  }
  &__wrapper {
    --size: 150px;
    width: var(--size);
    height: var(--size);
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    margin-right: 50px;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      display: block;
      border-radius: 10px;
    }
  }
}
</style>
