<template>
  <template v-if="loading"> Načítám profil... </template>
  <template v-else>
    <div class="page-head">
      <h1 v-if="account">
        Detail uživatele
        <span class="ft">
          {{ account.email }}
        </span>
      </h1>

      <h1 v-if="!account">Nový účet</h1>

      <div class="page-head__actions">
        <Button
          label="Zpět"
          @click="$router.push({ name: 'accounts-list' })"
          class="p-button-secondary"
          icon="pi pi-arrow-left"
        />
        <Button
          label="Smazat účet"
          @click="remove"
          v-if="account"
          class="p-button-secondary"
          icon="pi pi-trash"
        />
      </div>
    </div>

    <div class="box box--red p-mb-2" v-if="account && !account.emailVerified">
      <p>
        <strong>E-mailová adresa neověřena.</strong> Uživatel zatím neověřil
        svoji e-mailovou adresu přes odkaz v zaslané zprávě.
      </p>
    </div>
    <div class="box">
      <h2>Základní info</h2>
      <MainInfo :account="account" />
    </div>
  </template>
</template>

<script>
import { Accounts } from '@/services/repositories/Accounts';
import MainInfo from '../components/AccountMainInfo';
import Button from 'primevue/button';

export default {
  data() {
    return {
      account: null,
      loading: true,
    };
  },
  mounted() {
    let accountId = this.$route.params.id;

    if (accountId) {
      Accounts.getById(accountId).then((response) => {
        this.account = response;
        this.loading = false;
        document.title = this.account.email;
      });
    } else {
      this.loading = false;
      this.account = null;
      document.title = 'Nový účet';
    }
  },
  methods: {
    remove() {
      this.$confirm.require({
        message: 'Opravdu chcete smazat uživatele ' + this.account.email + '?',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-secondary',
        acceptClass: 'p-button-primary',
        accept: () => {
          Accounts.remove(this.account.id).then(() => {
            this.$toast.add({
              severity: 'success',
              summary: 'Účet ' + this.account.email + ' byl odstraněn.',
              life: 3000,
            });
            this.$router.push({ name: 'accounts-list' });
          });
        },
      });
    },
  },
  components: {
    MainInfo,
    Button,
  },
};
</script>

<style lang="scss" scoped></style>
