<template>
  <div class="topbar">
    <UserPanel />
  </div>
</template>

<script>
import UserPanel from './UserPanel';

export default {
  components: {
    UserPanel,
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 var(--spacing);
}
</style>
