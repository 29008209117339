<template>
  <Toasts />

  <div class="page-wrapper">
    <PublicLayout v-if="inPublicSection" />
    <SecuredLayout v-else />
  </div>

  <ConfirmDialog />
  <OverlayMessage />
  <Gallery />

  <link rel="stylesheet" href="https://use.typekit.net/akz7llh.css" />
</template>

<script>
import SecuredLayout from '@/components/securedLayout/SecuredLayout';
import OverlayMessage from '@/components/OverlayMessage';
import PublicLayout from '@/components/publicLayout/PublicLayout';
import { User } from '@/services/User';
import Toasts from '@/components/Toasts';
import ConfirmDialog from 'primevue/confirmdialog';
import { webpSupport } from '@/services/webpSupportDetect';
import Gallery from '@/components/Gallery';

export default {
  data() {
    return {
      inPublicSection: true,
    };
  },
  created() {
    const credentials = {
      token: localStorage.getItem('token'),
    };

    if (credentials.token != 'undefined') {
      window.eventBus.emit('show-overlay-message', {
        message: 'Kontroluji přihlášení...',
      });
      User.authenticate(credentials).then((data) => {
        window.eventBus.emit('hide-overlay-message');
        if (data.result != 'failed') {
          User.login(credentials.token, data.user);
        }
      });
    }

    webpSupport.detect();
  },
  mounted() {
    this.handlePageChange();

    window.eventBus.on('logout', () => {
      this.$router.push({ name: 'login' });
    });

    window.eventBus.on('login', () => {
      var redirect = this.$route.query.redirect;

      if (redirect) {
        this.$router.push(redirect);
      } else {
        this.$router.push({ name: 'dashboard' });
      }

      this.inPublicSection = false;
    });

    window.eventBus.on('login-failed', () => {
      this.inPublicSection = true;
    });

    window.eventBus.on('error', (e) => {
      window.eventBus.emit('hide-overlay-message');
      this.$toast.add({ severity: 'error', summary: e.message, life: 3000 });
    });
  },
  watch: {
    $route() {
      this.handlePageChange();
    },
  },
  methods: {
    handlePageChange() {
      let page = this.$route.meta;

      this.inPublicSection = page.public === true;

      if (page.title) {
        document.title = page.title;
      }
    },
  },
  components: {
    SecuredLayout,
    PublicLayout,
    Toasts,
    OverlayMessage,
    ConfirmDialog,
    Gallery,
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
