<template>
  <div :class="['overlay-message', cssClass]">
    <div class="overlay-message__text">
      {{ text }}
    </div>
    <div class="overlay-message__spinner" v-if="showSpinner"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: '',
      showSpinner: true,
      cssClass: '',
    };
  },
  created() {
    window.eventBus.on('show-overlay-message', (data) => {
      if (typeof data.showSpinner == 'undefined' || data.showSpinner) {
        this.showSpinner = true;
      } else {
        this.showSpinner = false;
      }

      this.text = data.text;
      this.cssClass = 'overlay-message--visible';
    });

    window.eventBus.on('hide-overlay-message', () => {
      this.cssClass = 'overlay-message--hiding';

      setTimeout(() => {
        this.cssClass = '';
      }, 500);
    });
  },
};
</script>

<style lang="scss" scoped>
.overlay-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#000, 0.9);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  display: none;
  transition: opacity 0.3s ease;
  &--visible {
    opacity: 1;
    display: flex;
    z-index: 9999;
  }
  &--hiding {
    display: flex;
    opacity: 0;
  }
  &__text {
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    width: 90%;
    max-width: 500px;
    text-align: center;
  }
}
</style>
