import { Rules } from '@/services/form/Rules';
import { FieldTypes } from '@/services/form/FieldTypes';

export default {
  fields: {
    name: {
      name: 'name',
      label: 'Název',
      value: null,
      defaultValue: '',
      type: FieldTypes.text,
      fullWidth: true,
      rules: [
        {
          type: Rules.required,
          message: 'Zvolte název.',
        },
        {
          type: Rules.maxLength,
          length: 255,
          message: 'Maximální délka názvu je 255 znaků.',
        },
      ],
    },
    url: {
      name: 'url',
      label: 'URL',
      value: null,
      defaultValue: '',
      type: FieldTypes.text,
      fullWidth: true,
      rules: [
        {
          type: Rules.required,
          message: 'Zvolte URL.',
        },
        {
          type: Rules.maxLength,
          length: 2083,
          message: 'Maximální délka názvu je 2083 znaků.',
        },
      ],
    },
    submit: {
      text: 'Uložit',
      name: 'submit',
      type: FieldTypes.submit,
    },
  },
  get() {
    return this.fields;
  },
  setValue(field, value) {
    this.fields[field].value = value;
  },
  setValuesFromEntity(feed) {
    this.setValue('name', feed.name);
    this.setValue('url', feed.url);
  },
  getValues() {
    let values = {};

    for (var name in this.fields) {
      let field = this.fields[name];

      if (field.value === 'undefined') {
        continue;
      }

      values[field.name] = field.value;
    }

    return values;
  },
  reset() {
    for (var name in this.fields) {
      let field = this.fields[name];

      if (field.defaultValue === 'undefined') {
        continue;
      }

      field.value = field.defaultValue;
    }
  },
};
