import { Rules } from './Rules';
import { FieldTypes } from './FieldTypes';
import FieldError from './Error';

export const Validator = {
  validate,
};

const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const PHONE_REGEX = /^(\+[0-9]{3})( )?([0-9]{3})( )?([0-9]{3})( )?([0-9]{3})$/;

function validate(fields) {
  let errors = [];

  for (var name in fields) {
    let field = fields[name];

    if (field.rules) {
      field.rules.forEach((rule) => {
        if (rule.type == Rules.required) {
          if (field.type == FieldTypes.checkbox) {
            if (field.value == false) {
              errors.push(new FieldError(field.name, rule.message));
            }
          } else if (
            field.type == FieldTypes.date ||
            field.type == FieldTypes.dateTime ||
            field.type == FieldTypes.time
          ) {
            if (field.value == '') {
              errors.push(new FieldError(field.name, rule.message));
            }
          } else if (
            field.type == FieldTypes.point ||
            field.type == FieldTypes.line ||
            field.type == FieldTypes.polygon
          ) {
            if (field.value === null || field.value == '') {
              errors.push(new FieldError(field.name, rule.message));
            }
          } else if (
            field.type == FieldTypes.dropdown ||
            field.type == FieldTypes.select
          ) {
            if (field.value === null) {
              errors.push(new FieldError(field.name, rule.message));
            }
          } else if (field.value.trim().length == 0) {
            errors.push(new FieldError(field.name, rule.message));
          }
        } else if (rule.type == Rules.minLength) {
          let length = field.value.trim().length;
          if (length != 0 && length < rule.length) {
            errors.push(new FieldError(field.name, rule.message));
          }
        } else if (rule.type == Rules.maxLength) {
          let length = field.value.trim().length;
          if (length > rule.length) {
            errors.push(new FieldError(field.name, rule.message));
          }
        } else if (rule.type == Rules.maxLengthWithoutTags) {
          let length = field.value.replace(/(<([^>]+)>)/gi, '').trim().length;
          if (length > rule.length) {
            errors.push(new FieldError(field.name, rule.message));
          }
        } else if (rule.type == Rules.sameAs) {
          if (field.value != fields[rule.sameAs].value) {
            errors.push(new FieldError(field.name, rule.message));
          }
        } else if (rule.type == Rules.email) {
          if (field.value.length && !EMAIL_REGEX.test(field.value)) {
            errors.push(new FieldError(field.name, rule.message));
          }
        } else if (rule.type == Rules.phone) {
          if (field.value.length && !PHONE_REGEX.test(field.value)) {
            errors.push(new FieldError(field.name, rule.message));
          }
        } else {
          throw 'Unknown form field rule.';
        }
      });
    }
  }

  return errors;
}
