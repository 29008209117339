<template>
  <div class="box">
    <div class="filter">
      <div class="filter__column">
        <season-filter @season-selected="seasonSelected" />
      </div>
      <div class="filter__column">
        <set-filter @set-selected="setSelected" />
      </div>
      <div class="filter__column">
        <subset-filter @subset-selected="subsetSelected" />
      </div>
      <div class="filter__column">
        <fulltext-filter @fulltext-filled="fulltextFilled" />
      </div>
    </div>
  </div>
</template>

<script>
import FulltextFilter from './FulltextFilter.vue';
import SeasonFilter from './SeasonFilter.vue';
import SetFilter from './SetFilter.vue';
import SubsetFilter from './SubsetFilter.vue';

export default {
  emits: ['filter-updated'],
  data() {
    return {
      filter: {
        season: null,
        set: null,
        subset: null,
        fulltext: null,
      },
    };
  },
  methods: {
    seasonSelected(season) {
      this.filter.season = season ? season.id : null;
      this.$emit('filter-updated', this.filter);
    },
    setSelected(set) {
      this.filter.set = set ? set : null;
      this.$emit('filter-updated', this.filter);
    },
    subsetSelected(subset) {
      this.filter.subset = subset ? subset : null;
      this.$emit('filter-updated', this.filter);
    },
    fulltextFilled(query) {
      this.filter.fulltext = query.length > 0 ? query : null;
      this.$emit('filter-updated', this.filter);
    },
  },
  components: {
    SeasonFilter,
    SetFilter,
    SubsetFilter,
    FulltextFilter,
  },
};
</script>

<style lang="scss" scoped>
.filter {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  &__column {
    flex-basis: 1;
  }
}
</style>
