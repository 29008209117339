import Dashboard from '../views/Dashboard';

export default [
  {
    name: 'dashboard',
    path: '/',
    components: {
      mainContent: Dashboard,
    },
    meta: {
      title: 'Nástěnka',
    },
  },
];
