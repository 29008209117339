import { API } from '@/services/API';

const BASE_URL = 'watch/';

const RssFeeds = {
  url: BASE_URL + 'rss-feed/',

  getAll: async function () {
    return API.get(this.url + 'get-all');
  },

  getById: async function (id) {
    return API.get(this.url + 'get-by-id/' + parseInt(id));
  },

  remove: async function (feed) {
    return API.get(this.url + 'remove/' + parseInt(feed.id));
  },

  save: async function (data) {
    return API.post(this.url + 'save', data);
  },

  parse: async function (data) {
    return API.get(this.url + 'parse/' + data.id);
  },
};

const Cards = {
  url: BASE_URL + 'cards/',

  getAll: async function(params) {
    return API.post(this.url + 'get-all', params);
  },

  getById: async function (id) {
    return API.get(this.url + 'get-by-id/' + parseInt(id));
  },

  remove: async function (card) {
    return API.get(this.url + 'remove/' + parseInt(card.id));
  },
};

const Prices = {
  url: BASE_URL + 'prices/',

  remove: async function (item) {
    return API.get(this.url + 'remove/' + parseInt(item.id));
  },
};

export { RssFeeds, Cards, Prices };
