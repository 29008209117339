<template>
  <div class="input">
    <div class="input__label">
      Sezona
    </div>
    <div class="input__input">
      <dropdown :showClear="true" v-model="selectedSeason" :options="seasons" optionLabel="name" placeholder="Filtrovat sezonu" />
    </div>
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import { Seasons } from '@/services/repositories/Collection';

export default {
  emits: [ 'season-selected' ],
  data() {
    return {
      loading: true,
      seasons: [],
      selectedSeason: null
    }
  },
  mounted() {
    this.loadSeasons();
  },
  watch: {
    selectedSeason() {
      this.$emit('season-selected', this.selectedSeason);
    }
  },
  methods: {
    loadSeasons() {
      this.loading = true;

      Seasons.getAll().then((data) => {
        this.seasons = data;
        this.loading = false;
      });
    }
  },
  components: {
    Dropdown
  }
};
</script>

<style lang="scss" scoped>
.input {
  display: flex;
  flex-direction: column;
  gap: 5px;
  &__label {
    font-size: .85rem;
  }
}
</style>
