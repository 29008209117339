<template>
  <div class="input">
    <div class="input__label">Sezona</div>
    <div class="input__input">
      <dropdown :showClear="true" v-model="selectedSet" :options="sets" placeholder="Filtrovat subset" />
    </div>
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import { Subsets } from '@/services/repositories/Collection';

export default {
  emits: ['subset-selected'],
  data() {
    return {
      loading: true,
      sets: [],
      selectedSet: null,
    };
  },
  mounted() {
    this.loadSeasons();
  },
  watch: {
    selectedSet() {
      this.$emit('subset-selected', this.selectedSet);
    },
  },
  methods: {
    loadSeasons() {
      this.loading = true;

      Subsets.getAll().then((data) => {
        this.sets = data;
        this.loading = false;
      });
    },
  },
  components: {
    Dropdown,
  },
};
</script>

<style lang="scss" scoped>
.input {
  display: flex;
  flex-direction: column;
  gap: 5px;
  &__label {
    font-size: 0.85rem;
  }
}
</style>
