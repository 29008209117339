import Registration from '../views/Registration';
import EmailVerified from '../views/EmailVerified';

export default [
  {
    name: 'registration-new',
    path: '/registrace',
    components: { mainContent: Registration },
    meta: {
      public: true,
      title: 'Nová registrace',
    },
  },
  {
    name: 'registration-emailVerified',
    path: '/registrace/overeni-emailu/:hash',
    components: { mainContent: EmailVerified },
    meta: {
      public: true,
      title: 'Ověření e-mailu',
    },
  },
];
