<template>
  <div class="page-head">
    <h1>Správa uživatelů</h1>

    <div class="page-head__actions">
      <Button
        @click="$router.push({ name: 'accounts-new' })"
        label="Vytvořit účet"
        icon="pi pi-plus"
      />
    </div>
  </div>

  <div class="box">
    <DataTable
      :value="accounts"
      :paginator="true"
      :rows="50"
      dataKey="id"
      :rowHover="true"
      v-model:filters="filters"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[30, 60, 100]"
      currentPageReportTemplate="Zobrazuji {first} až {last} z celkového počtu {totalRecords} účtů"
      :globalFilterFields="['email', 'name', 'town']"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="header">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              placeholder="Hledat..."
            />
          </span>
        </div>
      </template>

      <template #loading> Načítám uživatele... </template>
      <template #empty> Žádný účet neodpovídá zvoleným parametrům. </template>

      <Column field="name" header="Jméno" sortable>
        <template #body="{ data }">
          <router-link
            :to="{ name: 'accounts-detail', params: { id: data.id } }"
          >
            {{ data.name }}
          </router-link>
        </template>
      </Column>

      <Column field="email" header="E-mail" sortable>
        <template #body="{ data }">
          <router-link
            :to="{ name: 'accounts-detail', params: { id: data.id } }"
          >
            {{ data.email }}
          </router-link>
        </template>
      </Column>

      <Column field="town.name" header="Obec" sortable>
        <template #body="{ data }">
          <template v-if="data.town">
            {{ data.town.name }}
          </template>
          <template v-else> --- </template>
        </template>
      </Column>

      <Column field="lastActivity" header="Aktivní?">
        <template #body="{ data }">
          <span v-if="data.active"> ano </span>
          <span v-else> ne </span>
        </template>
      </Column>

      <Column field="active" header="Poslední přihlášení">
        <template #body="{ data }">
          <span v-if="data.lastActivity">
            {{ formatDate(data.lastActivity) }}
          </span>
          <em v-if="!data.lastActivity"> dosud nepřihlášen </em>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { Accounts } from '@/services/repositories/Accounts';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import Button from 'primevue/button';
import { FilterMatchMode } from 'primevue/api';
import moment from 'moment';

export default {
  data() {
    return {
      accounts: [],
      loading: true,
      roles: [],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  mounted() {
    Accounts.getAll().then((data) => {
      this.accounts = data;
      this.loading = false;
    });
  },
  methods: {
    formatDate(date) {
      moment.locale('cs');
      return moment(String(date)).format('llll');
    },
  },
  components: {
    DataTable,
    Column,
    InputText,
    Button,
  },
};
</script>

<style lang="scss" scoped></style>
