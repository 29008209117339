<template>
  <h1 class="heading heading--public tc cw">Ověření e-mailu</h1>

  <template v-if="!loading && !error">
    <p class="message">Vaše e-mailová adresa byla úspěšně ověřena.</p>
    <p class="message">
      Nyní prosím vyčkejte než naši administrátoři Váš účet aktivují. O aktivaci
      vás budeme informovat prostřednictvím vaší e-mailové adresy.
    </p>
    <p class="message">Toto okno nyní můžete zavřít.</p>
  </template>

  <p v-if="error" class="message">Tento odkaz již bohužel není platý.</p>
</template>

<script>
import { Registration } from '@/services/User';

export default {
  data() {
    return {
      loading: true,
      error: false,
    };
  },
  mounted() {
    window.eventBus.emit('show-overlay-message', {
      text: 'Kontroluji adresu...',
    });

    Registration.verifyEmail(this.$route.params.hash).then((response) => {
      window.eventBus.emit('hide-overlay-message');

      if (response.result != 'ok') {
        this.error = true;
        this.loading = false;
      } else {
        this.loading = false;
      }
    });
  },
};
</script>

<style lang="scss"></style>
