<template>
  <template v-if="!loading">
    <Form :fields="fields" :onSubmit="submit" />
  </template>
</template>

<script>
import { Accounts } from '@/services/repositories/Accounts';
import Form from '@/components/form/Form';
import MainInfoFields from '../services/forms/MainInfoFields';

export default {
  props: {
    account: Object,
  },
  data() {
    return {
      fields: MainInfoFields,
      loading: true,
    };
  },
  mounted() {
    this.fields.reset();

    if (this.account) {
      this.fields.setValuesFromEntity(this.account);
    }

    this.loading = false;
  },
  methods: {
    submit(values) {
      if (this.account) {
        values.id = this.account.id;
      }

      Accounts.save(values).then((response) => {
        if (response.result == 'ok') {
          window.eventBus.emit('success-toast', 'Učet uložen.');

          if (!this.account) {
            this.$router.push({
              name: 'accounts-detail',
              params: { id: response.id },
            });
          }
        } else {
          window.eventBus.emit('error-toast', 'Účet se nepodařilo uložit.');
        }
      });
    },
  },
  components: {
    Form,
  },
};
</script>

<style lang="scss" scoped></style>
