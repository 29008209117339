<template>
  <FileUpload
    class="p-mb-2"
    name="images[]"
    :url="url"
    @upload="imageUploaded"
    :auto="true"
    :multiple="true"
  >
    <template #empty>
      <p>
        Přetažením obrázku do tohoto obdélníku jej připojíte ke článku.
      </p>
    </template>
  </FileUpload>
</template>

<script>
import FileUpload from 'primevue/fileupload';
import { Cards } from '@/services/repositories/Collection';

export default {
  props: ['card'],
  computed: {
    url() {
      return Cards.Images.getUploadUrl(this.card);
    }
  },
  components: {
    FileUpload
  },
  methods: {
    imageUploaded(data) {
      var response = JSON.parse(data.xhr.response);

      if (response.result == 'ok') {
        this.$toast.add({
          severity: 'success',
          summary: 'Obrázek nahrán.',
          life: 3000
        });

        response.images.forEach(image => {
          window.eventBus.emit('image-added', image);
        });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Obrázek se nepodařilo nahrát.',
          detail: response.message,
          life: 3000
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
