import RssList from '../views/RssList';
import RssDetail from '../views/RssDetail';
import CardsList from '../views/CardsList';
import CardDetail from '../views/CardDetail';

const NAME_PREFIX = 'watch-';
const BASE_URL = '/sledovani';

export default [
  {
    name: NAME_PREFIX + 'rss-list',
    path: BASE_URL + '/rss',
    components: { mainContent: RssList },
    meta: { title: 'Sledované RSS feedy' },
  },
  {
    name: NAME_PREFIX + 'rss-new',
    path: BASE_URL + '/rss/novy',
    components: { mainContent: RssDetail },
    meta: { title: 'Nový RSS feed' },
  },
  {
    name: NAME_PREFIX + 'rss-detail',
    path: BASE_URL + '/rss/:id',
    components: { mainContent: RssDetail },
  },
  {
    name: NAME_PREFIX + 'cards-detail',
    path: BASE_URL + '/karta/:id',
    components: { mainContent: CardDetail },
  },
  {
    name: NAME_PREFIX + 'cards-list',
    path: BASE_URL + '/karty',
    components: { mainContent: CardsList },
    meta: { title: 'MojeKarty.cz' },
  },
];
