<template>
  <div class="picker">
    <ColorPicker v-model="selectedColor" />
  </div>
</template>

<script>
import ColorPicker from 'primevue/colorpicker';

export default {
  emits: ['color-picked'],
  props: {
    value: {
      type: String,
      default: null,
    },
    name: String,
  },
  data() {
    return {
      selectedColor: '#9F1D37',
    };
  },
  mounted() {
    if (this.value) {
      this.selectedColor = this.value;
    }
  },
  watch: {
    selectedColor() {
      this.$emit('color-picked', {
        name: this.name,
        value: this.selectedColor,
      });
    },
  },
  components: {
    ColorPicker,
  },
};
</script>
