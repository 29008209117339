<template>

    <DataTable
      :value="card.prices"
      data-key="id"
      :rowHover="true"
      responsiveLayout="scroll"
    >

      <template #empty>Žádné zjištěné ceny.</template>

      <Column field="price">
        <template #body="{ data }">
          ${{ data.price }}
        </template>
      </Column>

      <Column field="date">
        <template #body="{ data }">
          {{ formatDate(data.date) }}
        </template>
      </Column>

      <Column style="width: 1%">
        <template #body="{ data }">
          <div class="table--actions">
            <Button @click="remove(data)" icon="pi pi-trash" />
          </div>
        </template>
      </Column>
    </DataTable>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import { Prices } from '@/services/repositories/Watching';

export default {
  props: {
    card: Object,
  },
  methods: {
    formatDate(date) {
      const d = new Date(date);
      return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(d);
    },

    remove(price) {
      this.$confirm.require({
        message: 'Opravdu chcete cenu' + price.price + '?',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-secondary',
        acceptClass: 'p-button-primary',
        accept: () => {
          Prices.remove(price).then(() => {
            window.eventBus.emit('success-toast', 'Cena byla odstraněna.');
            window.eventBus.emit('prices-updated');
          });
        },
      });
    },
  },
  components: {
    DataTable,
    Button,
    Column
  },
};
</script>

<style lang="scss" scoped></style>
