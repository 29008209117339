import { API } from '@/services/API';

const BASE_URL = 'collection/cards/';

const Cards = {
  async get(params) {
    return API.post(BASE_URL + 'get', params);
  },

  async getById(id) {
    return API.get(BASE_URL + 'get-by-id/' + parseInt(id));
  },

  async remove(id) {
    return API.get(BASE_URL + 'remove/' + parseInt(id));
  },

  async duplicate(id) {
    return API.get(BASE_URL + 'duplicate/' + parseInt(id));
  },

  async save(data) {
    return API.post(BASE_URL + 'save', data);
  },

  Images: {
    url: BASE_URL + 'gallery/',

    async saveOrder(order) {
      return API.post(this.url + 'save-order', order);
    },

    async remove(id) {
      return API.get(this.url + 'remove/' + parseInt(id));
    },

    getUploadUrl(card) {
      return process.env.VUE_APP_API_URL + localStorage.getItem('token') + '/' + this.url + 'upload/' + card.id;
    }
  }
};

const Seasons = {
  url: BASE_URL + 'seasons/',

  async getAll() {
    return API.get(this.url + 'get-all');
  }
};

const Sets = {
  url: BASE_URL,

  async getAll() {
    return API.get(this.url + 'get-sets');
  }
};

const Subsets = {
  url: BASE_URL,

  async getAll() {
    return API.get(this.url + 'get-subsets');
  }
};

const Manufacturers = {
  url: BASE_URL + 'manufacturers/',

  async getAll() {
    return API.get(this.url + 'get-all');
  }
};

export { Cards, Seasons, Manufacturers, Sets, Subsets };
