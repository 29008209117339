export default {
  state: {
    user: {
      isLoggedIn: false,
      id: null,
      email: null,
      name: null,
      profilePicture: null,
      rights: [],
    },
  },
  mutations: {
    login(state, data) {
      state.user.isLoggedIn = true;
      state.user.email = data.email;
      state.user.name = data.name;
      state.user.id = data.id;
      state.user.rights = data.rights;
    },
    logout(state) {
      state.user.isLoggedIn = false;
    },
    revokeRight(state, rightToRevoke) {
      state.user.rights.forEach((right, i) => {
        if (right.id == rightToRevoke.id) {
          state.user.rights.splice(i, 1);
        }
      });
    },
    grantRight(state, right) {
      state.user.rights.push(right);
    },
  },
  getters: {
    getUser: (state) => {
      return state.user;
    },
  },
};
