<template>
  <Loading v-if="!isLoaded" text="Načítám ceny..." />
  <Chart v-else type="line" :data="data" :options="chartSettings" />
</template>

<script>
import Chart from 'primevue/chart';
import Loading from '@/components/Loading.vue';

export default {
  props: {
    card: Object,
  },
  data() {
    return {
      isLoaded: false,
      chartSettings: {
        plugins: {
          legend: {
            labels: {
              color: '#495057',
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: '#495057',
            },
            grid: {
              color: '#ebedef',
            },
          },
          y: {
            ticks: {
              color: '#495057',
            },
            grid: {
              color: '#ebedef',
            },
          },
        },
      },
      data: {
        labels: [],
        datasets: [],
      },
    };
  },
  mounted() {
    this.prepareChartData();
  },
  methods: {
    prepareChartData() {
      this.isLoaded = false;

      const labels = [];
      const data = [];

      this.card.prices.forEach((price) => {
        const dateObject = new Date(Date.parse(price.date));
        const date = dateObject.toISOString().substring(0, 10);

        labels.push(date);
        data.push(price.price);

        // @todo...
      });

      this.data.labels = labels;
      this.data.datasets.push({
        label: 'COMC',
        data: data,
        fill: false,
        borderColor: '#D92525',
        tension: 0.4,
      });

      this.isLoaded = true;
    },
  },
  components: {
    Chart,
    Loading,
  },
};
</script>

<style lang="scss" scoped></style>
