export default {
  methods: {
    formatPrice(price) {
      const formatter = new Intl.NumberFormat('cs-CS', {
        style: 'currency',
        currency: 'CZK',
      });

      return formatter.format(price);
    }
  }
}
