<template>
  <Toast position="bottom-left" />
</template>

<script>
import Toast from 'primevue/toast';

export default {
  created() {
    window.eventBus.on('error-toast', (message) => {
      this.$toast.add({
        severity: 'error',
        summary: message,
        life: 3000,
      });
    });
    window.eventBus.on('success-toast', (message) => {
      this.$toast.add({
        severity: 'success',
        summary: message,
        life: 3000,
      });
    });
  },
  components: {
    Toast,
  },
};
</script>
