<template>
  <div class="layout">
    <div class="layout__main-content">

      <div class="basic-info" v-if="card.season">
        <div class="basic-info__label">
          Sezona:
        </div>
        <div class="basic-info__value">
          {{ card.season.name }}
        </div>
      </div>
      <div class="basic-info" v-if="card.manufacturer">
        <div class="basic-info__label">
          Výrobce:
        </div>
        <div class="basic-info__value">
          {{ card.manufacturer.name }}
        </div>
      </div>
      <div class="basic-info" v-if="card.set">
        <div class="basic-info__label">
          Set:
        </div>
        <div class="basic-info__value">
          {{ card.set }}
        </div>
      </div>
      <div class="basic-info" v-if="card.number">
        <div class="basic-info__label">
          Číslo:
        </div>
        <div class="basic-info__value">
          {{ formatCardNumber(card.number) }}
        </div>
      </div>
      <div class="basic-info">
        <div class="basic-info__label">
          Subset:
        </div>
        <div class="basic-info__value" v-if="card.subset">
          {{ card.subset }}
        </div>
        <div class="basic-info__value" v-else>
          <span class="empty-value">base karta</span>
        </div>
      </div>
      <div class="basic-info">
        <div class="basic-info__label">
          Získáno od:
        </div>
        <div class="basic-info__value" v-if="card.acquiredFrom">
          {{ card.acquiredFrom }}
        </div>
        <div class="basic-info__value" v-else>
          <span class="empty-value">nezadáno</span>
        </div>
      </div>
      <div class="basic-info">
        <div class="basic-info__label">
          Další parametry:
        </div>
        <div class="basic-info__value">
          <Parameters :card="card" />
        </div>
      </div>
      <div class="basic-info basic-info--with-user-text">
        <div class="basic-info__label">
          Poznámka:
        </div>
        <div class="basic-info__value" v-if="card.note" v-html="card.note" />
        <div class="basic-info__value" v-else>
          <span class="empty-value">žádná poznámka</span>
        </div>
      </div>


    </div>
    <div class="layout__image">
      <div class="card-image" v-if="card.mainImage">
        <img :src="card.mainImage.file.main" />
      </div>
    </div>
  </div>
</template>

<script>
import Parameters from './CardParameters.vue';

export default {
  props: ['card'],
  methods: {
    formatCardNumber(number) {
      if (!isNaN(number)) {
        return '#' + number;
      } else {
        return number;
      }
    }
  },
  components: {
    Parameters
  }
};
</script>

<style lang="scss" scoped>
.layout {
  display: grid;
  gap: 20px;
  grid-template-columns: auto 400px;
  grid-template-areas: 'empty image';
  &__image {
    grid-area: image;
  }
}
.card-image {
  width: 100%;
  aspect-ratio: 25/35;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
.basic-info {
  display: grid;
  grid-template-columns: 150px auto;
  gap: 20px;
  font-size: .8rem;
  margin-bottom: 5px;
  &:last-of-type {
    margin-bottom: 0;
  }
  &__label {
    text-align: right;
  }
  &__value {
    font-weight: 700;
  }
  &--with-user-text {
    .basic-info__label {
      padding-top: 15px;
    }
    .basic-info__value {
      font-weight: 400;
    }
  }
}
.empty-value {
  color: #666;
  font-style: italic;
  font-weight: 400;
}
</style>
