<template>
  <template v-if="!loading">
    <Form :fields="fields" :onSubmit="submit" />
  </template>
</template>

<script>
import { Cards } from '@/services/repositories/Collection';
import Form from '@/components/form/Form';
import fields from '../services/MainFields';

export default {
  props: ['card'],
  data() {
    return {
      fields: fields,
      loading: true
    };
  },
  mounted() {
    this.fields.initFields().then(() => {
      if (this.card) {
        this.fields.setValuesFromEntity(this.card);
      } else {
        this.fields.reset();
      }
      this.loading = false;
    })
  },
  methods: {
    submit(values) {
      if (this.card) {
        values.id = this.card.id;
      }

      Cards.save(values).then(response => {
        if (response.result == 'ok') {
          this.$toast.add({
            severity: 'success',
            summary: 'Uloženo.',
            life: 3000
          });

          window.eventBus.emit('card-updated', response.id);
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Nepodařilo se uložit!',
            life: 3000
          });
        }
      });
    }
  },
  components: {
    Form
  }
};
</script>

<style lang="scss" scoped></style>
