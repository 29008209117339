export const Rules = {
  required: 'required',
  minLength: 'minLength',
  maxLength: 'maxLength',
  maxLengthWithoutTags: 'maxLengthWithoutTags',
  sameAs: 'sameAs',
  mask: 'mask',
  email: 'email',
  phone: 'phone',
};
