<template>
  <div class="public-page">
    <Logo />
    <div class="public-page__content-wrapper">
      <router-view name="mainContent" />
    </div>
  </div>
</template>

<script>
import Logo from './Logo';

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  components: {
    Logo,
  },
};
</script>

<style lang="scss">
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.public-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: linear-gradient(-45deg, var(--primary-color), var(--secondary-color));
  background-size: 400% 400%;
  animation: gradient 20s ease infinite;
  &__content-wrapper {
    position: relative;
    width: 100%;
    max-width: 500px;
    background: #fff;
    border-radius: 40px;
    padding: 60px 60px;
  }
  h1 {
    color: var(--primary-color);
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .logo {
    position: relative;
    svg {
      display: block;
      margin: 0 auto 80px auto;
      * {
        fill: #fff;
      }
    }
  }
  .message {
    line-height: 1.25;
  }
  .bottom-links {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .public-field-wrapper {
    width: 100%;
    max-width: 300px;
    margin-bottom: 25px;
  }
}
</style>
