<template>
  <h1 class="heading heading--public tc cw a a--fadeinbottom">
    Zapomenuté heslo
  </h1>

  <form
    v-on:submit.prevent="submit"
    class="form form--public a a--fadeinbottom a--delay-500"
    v-if="!sent"
  >
    <p class="message">
      Zadejte e-mailovou adresu, ke které jste ztratili heslo.
    </p>

    <div class="public-field-wrapper">
      <InputText
        type="text"
        v-model="email"
        placeholder="E-mail"
        class="form__field"
      />
    </div>

    <Button class="p-button--xxl p-button--black" @click="submit">
      Odeslat
    </Button>
  </form>

  <p v-if="sent" class="message">
    Na váš e-mail jsme odeslali odkaz pro nastavení nového hesla.
  </p>

  <div class="bottom-links">
    <router-link :to="{ name: 'login' }" class="bottom-links__link">
      zpět
    </router-link>
  </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { User } from '@/services/User';

export default {
  data() {
    return {
      email: '',
      sent: false,
    };
  },
  components: {
    Button,
    InputText,
  },
  methods: {
    submit() {
      if (this.email.length <= 0) {
        this.$toast.add({
          severity: 'error',
          summary: 'Vyplňte váš e-mail.',
          life: 3000,
        });
        return;
      } else {
        window.eventBus.emit('show-overlay-message', {
          text: 'Kontroluji e-mail...',
        });
      }

      User.sendPasswordRestoreEmail(this.email).then((response) => {
        window.eventBus.emit('hide-overlay-message');

        if (response.result == 'ok') {
          this.sent = true;
        } else {
          this.$toast.add({
            severity: 'error',
            summary: response.message,
            life: 3000,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.message {
  color: var(--primary-color);
  text-align: center;
}
</style>
