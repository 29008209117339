<template>
  <Loading v-if="loading" text="Načítám feed..." />
  <template v-else>
    <div class="page-head">
      <h1 v-if="feed">
        RSS feed:
        <span class="ft">
          {{ feed.name }}
        </span>
      </h1>

      <h1 v-if="!feed">Nový RSS feed</h1>

      <div class="page-head__actions">
        <Button
          label="Zpět"
          @click="$router.push({ name: 'watch-rss-list' })"
          class="p-button-secondary"
          icon="pi pi-arrow-left"
        />
        <Button
          label="Smazat"
          @click="remove"
          v-if="feed"
          class="p-button-secondary"
          icon="pi pi-trash"
        />
      </div>
    </div>

    <div class="box">
      <MainInfoForm :feed="feed" />
    </div>
  </template>
</template>

<script>
import { RssFeeds } from '@/services/repositories/Watching';
import MainInfoForm from '../components/MainInfoForm';
import Button from 'primevue/button';
import Loading from '@/components/Loading.vue';

export default {
  data() {
    return {
      feed: null,
      loading: true,
    };
  },
  mounted() {
    this.initData();

    window.eventBus.on('feed-created', (data) => {
      this.initData(data);
    });
  },
  unmounted() {
    window.eventBus.off('feed-created');
  },
  watch: {
    $route(to, from) {
      if (to.name == from.name) {
        this.initData();
      }
    },
  },
  methods: {
    initData(feedId) {
      this.loading = true;
      feedId = feedId || this.$route.params.id;

      if (feedId) {
        RssFeeds.getById(feedId).then((response) => {
          this.feed = response;
          this.loading = false;
          document.title = this.feed.name;
        });
      } else {
        this.loading = false;
        this.feed = null;
        document.title = 'Nový feed';
      }
    },
    remove() {
      this.$confirm.require({
        message: 'Opravdu chcete smazat feed ' + this.feed.name + '?',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-secondary',
        acceptClass: 'p-button-primary',
        accept: () => {
          RssFeeds.remove(this.feed).then(() => {
            this.$router.push({ name: 'saleHomes-list' });
            window.eventBus.emit(
              'success-toast',
              'Feed ' + this.feed.name + ' byl odstraněn.'
            );
          });
        },
      });
    },
  },
  components: {
    MainInfoForm,
    Loading,
    Button,
  },
};
</script>

<style lang="scss" scoped></style>
