import Login from '../views/Login';
import ForgottenPassword from '../views/ForgottenPassword';
import PasswordRestore from '../views/PasswordRestore';

export default [
  {
    name: 'login',
    path: '/login',
    components: {
      mainContent: Login,
    },
    meta: {
      public: true,
      title: 'Přihlášení',
    },
  },
  {
    path: '/zapomenute-heslo',
    name: 'forgottenPassword',
    components: {
      mainContent: ForgottenPassword,
    },
    meta: {
      public: true,
      title: 'Zapomenuté heslo',
    },
  },
  {
    path: '/obnova-hesla/:hash',
    components: {
      mainContent: PasswordRestore,
    },
    meta: {
      public: true,
      title: 'Obnova hesla',
    },
  },
];
