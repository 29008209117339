import List from '../views/List';
import Detail from '../views/Detail';

const BASE_NAME = 'accounts-';
const BASE_URL = '/uzivatele/';

export default [
  {
    name: BASE_NAME + 'list',
    path: BASE_URL + 'seznam',
    components: { mainContent: List },
    meta: { title: 'Správa uživatelů' },
  },
  {
    name: BASE_NAME + 'new',
    path: BASE_URL + 'novy',
    components: { mainContent: Detail },
    meta: { title: 'Nový uživatel' },
  },
  {
    name: BASE_NAME + 'detail',
    path: BASE_URL + 'detail/:id',
    components: { mainContent: Detail },
  },
  {
    name: BASE_NAME + 'groups',
    path: BASE_URL + 'skupiny',
    components: { mainContent: List },
    meta: { title: 'Skupiny uživatelů' },
  },
];
